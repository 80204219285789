/* eslint-disable require-jsdoc */
import {
    CatchDeclarationPropertyUnitConfiguration,
    DeclarationCatchProperty,
    DeclarationFisherRequirementPropertyDetails,
    DeclarationSpeciesPropertyRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
    UnitConfiguration,
} from '@oma-kala-shared/core/model';
import { DYNAMIC_FIELD_TYPE, FieldValueType, GridField, GridValue } from 'app/components/common/dynamic-grid';
import { t } from 'i18next';
import moment from 'moment';
import { getFieldNameInGridValue } from '../form/catch/details/CatchRegistryPropertyNameMapper';

export type GenerateFieldsFromRequirementsOptions = {
    requirements: DeclarationPropertyRequirements[];
    fieldFiltering?: (requirement: DeclarationPropertyRequirements) => boolean;
    predefinedDefaultValues?: Record<string, FieldValueType>;
    visibilityHandler?: (requirement: DeclarationPropertyRequirements) => boolean;
    fieldTypeHandler?: (requirement: DeclarationPropertyRequirements) => DYNAMIC_FIELD_TYPE;
    predefinedValidationRules?: Record<string, string>;
    fieldPostHandler?: (field: GridField) => GridField;
    fieldLabelHandler?: (requirement: DeclarationPropertyRequirements) => string;
    disabledFields?: string[];
};

class CatchDeclarationRequirementHelper {
    static getValidationRuleForType(type: string): string {
        switch (type.toLowerCase()) {
            case DYNAMIC_FIELD_TYPE.STRING:
                return 'required|min:1';
            case DYNAMIC_FIELD_TYPE.FLOAT:
                return 'numeric|min:0';
            case DYNAMIC_FIELD_TYPE.INTEGER:
                return 'numeric|min:0';
            default:
                return 'required';
        }
    }

    static getDefaultValueForType(type: string): FieldValueType {
        switch (type.toLowerCase()) {
            case DYNAMIC_FIELD_TYPE.STRING:
                return '';
            case DYNAMIC_FIELD_TYPE.FLOAT:
                return 0.0;
            case DYNAMIC_FIELD_TYPE.INTEGER:
                return 0;
            case DYNAMIC_FIELD_TYPE.DATE:
                return moment();
            default:
                return '';
        }
    }

    static generateCatchPropertiesFromGridValue = (
        requiredProperties: DeclarationSpeciesPropertyRequirementDetails[],
        existingCatchProperties: DeclarationCatchProperty[],
        gridValue: GridValue
    ): DeclarationCatchProperty[] => {
        return requiredProperties.map(requiredProperty => {
            const value = gridValue![getFieldNameInGridValue(requiredProperty.name)];
            const existingProperty = existingCatchProperties.find(prop => prop.propertyName === requiredProperty.name);

            return {
                id: existingProperty?.id ?? null,
                idRequiredTaxonomyProperty: requiredProperty.id,
                value: value?.toString() ?? CatchDeclarationRequirementHelper.getDefaultValueForType(requiredProperty.dataType).toString(),
                propertyName: requiredProperty.name,
                dataType: requiredProperty.dataType,
                unit: requiredProperty.unit!,
            };
        });
    };

    static generateFieldsFromRequirements = (options: GenerateFieldsFromRequirementsOptions): [Record<string, GridField>, GridValue] => {
        const fields: Record<string, GridField> = {};
        const fieldValues: GridValue = {};
        const {
            requirements,
            fieldFiltering,
            predefinedDefaultValues,
            visibilityHandler,
            fieldTypeHandler,
            predefinedValidationRules,
            fieldPostHandler,
            fieldLabelHandler,
            disabledFields,
        } = options;

        requirements.forEach(requiredProperty => {
            if (fieldFiltering && fieldFiltering(requiredProperty) === false) {
                return;
            }

            let value = CatchDeclarationRequirementHelper.getDefaultValueForType(requiredProperty.dataType);
            if (predefinedDefaultValues && predefinedDefaultValues[requiredProperty.name] !== undefined) {
                value = predefinedDefaultValues[requiredProperty.name];
            }

            fieldValues[requiredProperty.name] = value;
            if (visibilityHandler && visibilityHandler(requiredProperty) === false) {
                return;
            }

            let fieldType: string = requiredProperty.dataType;

            if (fieldTypeHandler) {
                fieldType = fieldTypeHandler(requiredProperty) as string;
            }

            let validationRule: string = CatchDeclarationRequirementHelper.getValidationRuleForType(requiredProperty.dataType);

            if (predefinedValidationRules && predefinedValidationRules[requiredProperty.name]) {
                validationRule = predefinedValidationRules[requiredProperty.name];
            }

            let label: string = requiredProperty.name;

            if (fieldLabelHandler) {
                label = fieldLabelHandler(requiredProperty);
            }

            let disabled = false;

            if (disabledFields && disabledFields.includes(requiredProperty.name)) {
                disabled = true;
            }

            let field: GridField = {
                id: requiredProperty.id as number,
                type: fieldType as DYNAMIC_FIELD_TYPE,
                inputSize: '98%',
                containerProps: {
                    xs: 6,
                },
                name: requiredProperty.name,
                label,
                required: true,
                unit: requiredProperty.unit,
                disabled: disabled,
                value,
                validationRule,
            };

            // Handling optional for taxonomy required properties
            if ('isOptional' in requiredProperty && requiredProperty.isOptional === true) {
                field.required = false;
                field.validationRule = '';
            }

            if (requiredProperty.unit !== null) {
                field.unitConfiguration = CatchDeclarationPropertyUnitConfiguration[
                    requiredProperty.name as DeclarationSpeciesRequirementPropertyName
                ] as UnitConfiguration;
            }

            if (fieldPostHandler) {
                field = fieldPostHandler(field);
            }

            fields[requiredProperty.name] = field;
        });

        return [fields, fieldValues];
    };

    static checkDuplicateProperties = (properties: DeclarationSpeciesPropertyRequirementDetails[]): string[] => {
        const seenProperties: Set<string> = new Set();
        const propertyMap: Map<string, DeclarationSpeciesPropertyRequirementDetails> = new Map();

        const duplicates: string[] = [];

        properties.forEach(property => {
            if (seenProperties.has(property.name)) {
                const existingProperty = propertyMap.get(property.name);
                if (existingProperty && existingProperty.isBulkProperty !== property.isBulkProperty) {
                    duplicates.push(property.name);
                }
            } else {
                seenProperties.add(property.name);
                propertyMap.set(property.name, property);
            }
        });

        return duplicates;
    };

    static handleReleaseCountProperties = (gridValue: GridValue) => {
        if (gridValue !== null) {
            if (
                +gridValue[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)] ===
                +gridValue[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT)]
            ) {
                gridValue[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT)] =
                    +gridValue[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)];
            } else {
                gridValue[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT)] = 0;
            }
        }

        return gridValue;
    };

    static isStatisticProperty = (name: string): boolean => {
        const detailsRequirements: string[] = [
            DeclarationSpeciesRequirementPropertyName.CATCH_DATE,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR,
        ];

        return !detailsRequirements.includes(name);
    };

    static updateFieldsWithCatchProperties = (
        fields: Record<string, GridField>,
        fieldValues: Record<string, FieldValueType>,
        catchProperties: DeclarationCatchProperty[]
    ): { updatedFields: Record<string, GridField>; updatedValues: Record<string, FieldValueType> } => {
        let updatedValues = {
            ...fieldValues,
        };

        if (catchProperties.length > 0) {
            catchProperties.forEach(property => {
                const name = property.propertyName?.toUpperCase() as string;

                if (fields[name]) {
                    let value = property.value;
                    if (property.dataType === DYNAMIC_FIELD_TYPE.DATE) {
                        value = moment.utc(property.value).toISOString();
                    }

                    if (name === DeclarationSpeciesRequirementPropertyName.COUNT) {
                        value = value.toString();
                    }

                    updatedValues = {
                        ...updatedValues,
                        [name]: value,
                    };

                    fields[name].value = value;
                }
            });
        }

        return { updatedFields: fields, updatedValues };
    };
}

export default CatchDeclarationRequirementHelper;

export type DeclarationPropertyRequirements = DeclarationSpeciesPropertyRequirementDetails | DeclarationFisherRequirementPropertyDetails;
