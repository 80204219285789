import React, { useState } from 'react';
import { BaseFieldProps, DynamicFieldRef, GridSelectField } from '..';
import { Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material';

export type CheckboxFieldProps = BaseFieldProps & {
    field: GridSelectField;
};

const CheckboxField = React.forwardRef<DynamicFieldRef, CheckboxFieldProps>(({ field, onChange, helperText, error }, ref) => {
    const { name, inputSize, value, label, sx, items } = field;
    const [currentValue, setCurrentValue] = useState<boolean>(!!+value);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.checked ? 1 : 0;
        setCurrentValue(e.target.checked);
        onChange(name, newValue);
    };

    return (
        <FormControlLabel
            sx={{
                // backgroundColor,
                width: inputSize,
                ...(sx ?? {}),
            }}
            label={label}
            control={
                <Checkbox
                    name={name}
                    sx={{ alignSelf: 'center' }}
                    checked={!!currentValue}
                    onChange={handleOnChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            }
        />
    );
});

CheckboxField.displayName = 'Select Field';

export default CheckboxField;
