import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DeclarationRequirementDetails } from '@oma-kala-shared/core/model';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CreateDeclarationForm, { CreateDeclarationFormRef } from 'app/components/declaration/form/CreateDeclarationForm';
import { ContentPage } from 'app/components/layout';
import { useSyncToken } from 'app/hooks';
import { selectDeclarationRequirements } from 'app/state/declaration/declarationSlice';
import { fetchDeclarationRequirements } from 'app/state/thunks/fetchDeclarationRequirements';
import { appTheme } from 'app/theme';
import { display } from '@mui/system';
import { useLocation, useParams } from 'react-router-dom';

export const CreateDeclarationPage = (): JSX.Element => {
    const { t } = useTranslation();
    const { type } = useParams();

    const isGroupDeclaration = type === 'group';
    const dispatch = useDispatch();
    const catchFormRef = useRef<CreateDeclarationFormRef>(null);
    const declarationRequirements: DeclarationRequirementDetails[] = useSelector(selectDeclarationRequirements);
    const [showAddButton, setShowAddButton] = useState<boolean>(true);

    const currentDeclarationRequirement: DeclarationRequirementDetails | null =
        declarationRequirements.length > 0 ? declarationRequirements[0] : null;

    const [syncToken, isLoading] = useSyncToken({
        onSuccess: () => null,
        onError: () => null,
    });

    const showCatchForm = () => {
        catchFormRef.current?.openCatchForm();
    };

    useEffect(() => {
        dispatch(fetchDeclarationRequirements(syncToken));
    }, []);

    return (
        <ContentPage width="55%">
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sx={{
                        paddingRight: 3,
                    }}
                >
                    {!isLoading && currentDeclarationRequirement === null ? (
                        <Typography variant="h4" sx={{ marginBottom: 6 }}>
                            {t('declaration.noDeclarationRequiremntsFound')}
                        </Typography>
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', gap: '4px', justifyContent: 'space-between', marginBottom: 6 }}>
                                <Typography variant="h4">
                                    {t(`catchDeclaration.newDeclaration.title.${isGroupDeclaration ? 'group' : 'personal'}`)}
                                </Typography>
                            </Box>
                            <CreateDeclarationForm
                                ref={catchFormRef}
                                isLoading={isLoading}
                                declarationRequirement={currentDeclarationRequirement}
                                isGroupDeclaration={isGroupDeclaration}
                                onCatchFormClose={() => setShowAddButton(true)}
                                onCatchFormShow={() => setShowAddButton(false)}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </ContentPage>
    );
};
