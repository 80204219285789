import React, { useImperativeHandle, useRef, useState } from 'react';
import {
    DeclarationFisherProperty,
    DeclarationFisherRequirementPropertyDetails,
    DeclarationFisherRequirementPropertyName,
} from '@oma-kala-shared/core/model';

import { useTranslation } from 'react-i18next';
import { GridValue, GridField, DYNAMIC_FIELD_TYPE, DynamicGridRef, FieldValueType } from 'app/components/common/dynamic-grid';
import DynamicGrid from 'app/components/common/dynamic-grid/DynamicGrid';
import CatchDeclarationRequirementHelper, { DeclarationPropertyRequirements, GenerateFieldsFromRequirementsOptions } from '../../../helpers/CatchDeclarationRequirementHelper';
import moment from 'moment';
import { selectUserData, selectUserIsAdmin, selectUserIsEditor, selectUserRoles } from 'app/state/user/userSlice';
import { useSelector } from 'react-redux';

type PersonalDetailsProps = {
    fisherProperties?: DeclarationFisherProperty[];
    requirementProperties: DeclarationFisherRequirementPropertyDetails[];
    onValueChange: (latestValue: GridValue) => void;
};

type PersonalDetailsRef = Omit<DynamicGridRef, 'setError'>;

const PersonalDetails = React.forwardRef<PersonalDetailsRef, PersonalDetailsProps>(
    ({ fisherProperties, requirementProperties, onValueChange }: PersonalDetailsProps, ref) => {
        const gridValue = useRef<GridValue | null>({});
        const dynamicGridRef = useRef<DynamicGridRef | null>(null);
        const { t } = useTranslation();
        const userData = useSelector(selectUserData);
        const isAdminOrEditor = useSelector(selectUserIsAdmin) === true || useSelector(selectUserIsEditor) === true;

        const handleValueChange = (fieldValues: GridValue) => {
            gridValue.current = {
                ...(gridValue.current ?? {}),
                ...fieldValues,
            };
            onValueChange(gridValue.current);
        };

        useImperativeHandle(ref, () => ({
            validate: () => {
                return dynamicGridRef.current?.validate() as boolean;
            },
            getValue: () => {
                return gridValue.current ?? null;
            },
        }));

        /**
         * For now fisher form only render when we have fisher requirements
         */
        if (requirementProperties.length === 0) {
            return <></>;
        }

        // Need to create fields from requiredFields
        const preDefinedValues: Record<string, FieldValueType> = {
            [DeclarationFisherRequirementPropertyName.COUNT]: 1,
            [DeclarationFisherRequirementPropertyName.STREET_ADDRESS]: isAdminOrEditor ? '' : userData?.addressStreet ?? '',
            [DeclarationFisherRequirementPropertyName.MUNICIPALITY]: isAdminOrEditor ? '' : userData?.municipality ?? '',
            [DeclarationFisherRequirementPropertyName.BIRTH_DATE]: isAdminOrEditor ? '' : userData?.birthDateIso ?? moment().toISOString(),
            [DeclarationFisherRequirementPropertyName.FIRST_NAME]: isAdminOrEditor ? '' : userData?.firstName ?? '',
            [DeclarationFisherRequirementPropertyName.LAST_NAME]: isAdminOrEditor ? '' : userData?.lastName ?? '',
        };

        const visibilityHandler = (property: DeclarationPropertyRequirements): boolean => {
            const hideFields: string[] = [DeclarationFisherRequirementPropertyName.COUNT];

            return !hideFields.includes(property.name);
        };

        const fieldLabelHandler = (property: DeclarationPropertyRequirements): string => {
            return t(`catchDeclaration.fisherProperties.personal.${property.name}`);
        };

        // Need to create fields from requiredFields
        const options: GenerateFieldsFromRequirementsOptions = {
            requirements: requirementProperties,
            predefinedDefaultValues: preDefinedValues,
            visibilityHandler,
            fieldLabelHandler,
            disabledFields: isAdminOrEditor
                ? []
                : [
                      DeclarationFisherRequirementPropertyName.STREET_ADDRESS,
                      DeclarationFisherRequirementPropertyName.MUNICIPALITY,
                      DeclarationFisherRequirementPropertyName.BIRTH_DATE,
                      DeclarationFisherRequirementPropertyName.FIRST_NAME,
                      DeclarationFisherRequirementPropertyName.LAST_NAME,
                  ],
        };

        const [fields, fieldValues] = CatchDeclarationRequirementHelper.generateFieldsFromRequirements(options);

        /**
         * Update the ref value
         */
        gridValue.current = {
            ...(gridValue.current ?? {}),
            ...fieldValues,
        };

        // When we have the fisher properties, need to set value to field and fieldValue ref
        if (fisherProperties && fisherProperties.length > 0) {
            fisherProperties.forEach(property => {
                const name = property.propertyName?.toUpperCase() as string;

                if (fields[name]) {
                    let value = property.value;
                    if (property.dataType === DYNAMIC_FIELD_TYPE.DATE) {
                        value = moment.utc(property.value).toISOString();
                    }

                    gridValue.current = {
                        ...(gridValue.current ?? {}),
                        [name]: value,
                    };

                    fields[name].value = value;
                }
            });
        }

        return <DynamicGrid ref={dynamicGridRef} gridFields={[Object.values(fields)]} onValueChange={handleValueChange} />;
    }
);

PersonalDetails.displayName = 'FisherPersonalDetails';

export default PersonalDetails;
