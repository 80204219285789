import { ListItem } from '@mui/material';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import DeclarationItem from '../../components/declaration/display/DeclarationItem';
import {
    DeclarationCatchDetails,
    DeclarationDetails,
    DeclarationSearchRequest,
    DeclarationSearchResult,
    UserDeclarationSearchRequest,
} from '@oma-kala-shared/core/model';
import {
    DefaultDeclarationSearchParams,
    DefaultUserDeclarationSearchParams,
} from '../../components/declaration/modal/DeclarationSearchModal';
import { useSelector } from 'react-redux';
import { setErrorMessage } from 'app/state/message/messageSlice';
import { selectUserIsAdmin } from 'app/state/user/userSlice';
import { DeclarationCatchItem } from 'app/components/declaration/display/DeclarationCatchItem';
import DeclarationCatchList from 'app/components/declaration/display/DeclarationCatchList';
import { DeclarationListPageEditContent } from './content/DeclarationListPageEditContent';
import DeclarationListPageListContent, { DeclarationListPageListContentRef } from './content/DeclarationListPageListContent';
import { useSearchDeclaration } from 'app/hooks/useSearchDeclaration';

export enum PageTypeEnum {
    GROUP = 'group',
    PERSONAL = 'personal',
}

export type PageType = PageTypeEnum.GROUP | PageTypeEnum.PERSONAL;

/**
 * Create a default update request for the given user data
 * @param {UserData | null} userData
 * @return {UpdateUserRequest}
 */

/**
 * @return {JSX.Element}
 */
export function DeclarationListPage() {
    const listContentRef = useRef<DeclarationListPageListContentRef>(null);
    const [urlSearchParams] = useSearchParams();
    const selectedListType: string = urlSearchParams.get('type') ?? PageTypeEnum.PERSONAL;
    const [editingDeclaration, setEditingDeclaration] = useState<DeclarationDetails | null>(null);
    const [declarationSearchResult, setDeclarationSearchResult] = React.useState<DeclarationSearchResult | null>(null);
    const [searchParams, setSearchParams] = React.useState<UserDeclarationSearchRequest | DeclarationSearchRequest | null>(null);
    const searchPageRef = useRef<number>(0);
    const [currentType, setCurrentType] = React.useState<PageType>(selectedListType as PageType);
    const [canLoadMore, setCanLoadMore] = React.useState(true);
    const userIsAdmin: boolean = useSelector(selectUserIsAdmin);
    const { t } = useTranslation();

    const getDefaultSearchParams = (pageType: PageType) => {
        const params = userIsAdmin ? DefaultDeclarationSearchParams : DefaultUserDeclarationSearchParams;
        return { ...params, isGroup: pageType === PageTypeEnum.GROUP };
    };

    const onSearchSuccess = (results: DeclarationSearchResult) => {
        setCanLoadMore(results.declarations.length > 0);
        searchPageRef.current = searchParams?.page ?? 1;
        if (!declarationSearchResult) {
            setDeclarationSearchResult(results);
            return;
        }

        const updatedResults = {
            ...declarationSearchResult,
            declarations: [...declarationSearchResult.declarations, ...results.declarations],
        };
        console.log(updatedResults);
        setDeclarationSearchResult(updatedResults);
    };

    const onSearchError = () => setErrorMessage(t('common.error'));

    const { searchDeclarations, loading } = useSearchDeclaration(onSearchSuccess, onSearchError);

    useEffect(() => {
        const searchParams = getDefaultSearchParams(currentType);
        setSearchParams(searchParams);
        searchPageRef.current = searchParams.page;

        if (editingDeclaration) {
            setEditingDeclaration(null);
        }
    }, [currentType]);

    useEffect(() => {
        if (!searchParams) {
            return;
        }
        searchDeclarations(searchParams);
    }, [searchParams]);

    useEffect(() => {
        if (selectedListType !== currentType) {
            setDeclarationSearchResult(null);
            setCurrentType(selectedListType as PageType);
        }
    }, [selectedListType]);

    const handleBackButton = () => {
        setEditingDeclaration(null);
    };

    const updateDeclaration = (updatedDeclaration: DeclarationDetails) => {
        setEditingDeclaration(null);
        if (!declarationSearchResult) return;
        const newDeclarationSearchResult = declarationSearchResult.declarations.map((x: DeclarationDetails) => {
            if (x.id === updatedDeclaration.id) {
                return updatedDeclaration;
            }

            return x;
        });
        setDeclarationSearchResult({ ...declarationSearchResult, declarations: newDeclarationSearchResult });
    };

    const handleEditDeclaration = (catchDeclaration: DeclarationDetails) => {
        setEditingDeclaration(catchDeclaration);
    };

    const handleRemoveDeclaration = (id: string) => {
        if (!declarationSearchResult) return;
        const updatedDeclarations = declarationSearchResult.declarations.filter(x => x.id !== id);
        setDeclarationSearchResult({
            ...declarationSearchResult,
            declarations: updatedDeclarations,
            totalElements: declarationSearchResult.totalElements - 1,
        });

        if (userIsAdmin) {
            listContentRef.current?.showDeleteNoticeModal();
        }
    };

    const loadMoreDeclarations = () => {
        setSearchParams({ ...searchParams!, page: searchPageRef.current + 1 });
    };

    const onSearchDialogClosed = (searchParams: DeclarationSearchRequest | null) => {
        setDeclarationSearchResult(null);
        setSearchParams({ ...searchParams, isGroup: currentType === PageTypeEnum.GROUP } as DeclarationSearchRequest);
    };

    const declarationItemRenderer = useCallback(
        (declaration: DeclarationDetails) => {
            return (
                <ListItem key={declaration.id}>
                    <DeclarationItem
                        key={declaration.id}
                        declaration={declaration}
                        onDelete={handleRemoveDeclaration}
                        onEdit={handleEditDeclaration}
                        itemRenderer={() => {
                            return (
                                <DeclarationCatchList
                                    catches={declaration.catches}
                                    sorter={DeclarationCatchList.sortByDate}
                                    itemRenderer={(catchDetail: DeclarationCatchDetails) => {
                                        return <DeclarationCatchItem key={catchDetail.id} catchItem={catchDetail} isListView={true} />;
                                    }}
                                />
                            );
                        }}
                    />
                </ListItem>
            );
        },
        [declarationSearchResult]
    );

    if (editingDeclaration) {
        return (
            <DeclarationListPageEditContent
                editingDeclaration={editingDeclaration}
                pageType={selectedListType as PageType}
                onEditFormSaved={updateDeclaration}
                onBackButtonClicked={handleBackButton}
            />
        );
    }

    return (
        <DeclarationListPageListContent
            pageType={selectedListType as PageType}
            onEdit={handleEditDeclaration}
            itemRenderer={declarationItemRenderer}
            onFetchMoreResults={loadMoreDeclarations}
            onSearchDialogClosed={onSearchDialogClosed}
            loading={loading}
            canLoadMore={canLoadMore}
            searchResults={declarationSearchResult}
            ref={listContentRef}
            searchParams={searchParams as DeclarationSearchRequest | null}
        />
    );
}
