import { CatchRegistryDeclarationSpeciesRequirementPropertyNameMap } from '@oma-kala-shared/core/model';

/**
 * Get name of property to show on frontend.
 *
 * Because the enum in shared repo does not contains the word 'CUT' for adipose related fields
 * but the catch-registry service return the name contains 'CUT'
 *
 * so this function is used to get correct name to use to be able to work with enum, for example
 * use with key inside translation
 *
 * Example:
 * catch-registry return ADIPOSE_FIN_CUT_COUNT
 * enum contains ADIPOSE_FIN_COUNT
 *
 * @param {string} propertyName
 *
 * @return {string}
 */
export const getPropertyNameOnFrontend = (propertyName: string) => {
    // In case catch-registry service use difference name. Ex: ADIPOSE_FIN_CUT_COUNT instead of ADIPOSE_FIN_COUNT
    if (propertyName in CatchRegistryDeclarationSpeciesRequirementPropertyNameMap) {
        propertyName = CatchRegistryDeclarationSpeciesRequirementPropertyNameMap[propertyName];
    }

    return propertyName;
};

/**
 * This function is used to get correct field's name to be used in grid value
 *
 * Because the enum in shared repo does not contains the word 'CUT' for adipose related fields
 * but the catch-registry service return the name contains 'CUT'
 *
 * Example:
 * catch-registry return ADIPOSE_FIN_CUT_COUNT
 * enum contains ADIPOSE_FIN_COUNT
 *
 * @param {string} name
 *
 * @return {string}
 */
export const getFieldNameInGridValue = (name: string) => {
    const foundName = Object.keys(CatchRegistryDeclarationSpeciesRequirementPropertyNameMap).find(
        key => CatchRegistryDeclarationSpeciesRequirementPropertyNameMap[key] === name
    );

    return foundName ?? name;
};
