import {
    CatchDeclarationPropertyUnitConfiguration,
    ChildCatchDetails,
    DeclarationSpeciesPropertyRequirementDetails,
    DeclarationSpeciesRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
} from '@oma-kala-shared/core/model';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { DYNAMIC_FIELD_TYPE, FieldValueType, GridUnitField, GridValue } from 'app/components/common/dynamic-grid';
import { Button, Divider } from '@mui/material';
import UnitField from 'app/components/common/dynamic-grid/fields/UnitField';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { ChildCatchItem } from './ChildCatchItem';
import { uuidv4 } from '@oma-kala-shared/core/logic';

type BulkCatchIndividualCatchGridState = {
    childCatches: ChildCatchDetails[];
    aggregateValue: GridValue;
    taxonomyRequirement: DeclarationSpeciesRequirementDetails;
};

type BulkCatchIndividualCatchGridContextValue = {
    state: BulkCatchIndividualCatchGridState;
    handleAddItem: (newItem: ChildCatchDetails) => void;
    handleRemoveItem: (removeItem: ChildCatchDetails) => void;
    handleUpdateItem: (updatedItem: ChildCatchDetails) => void;
};

export type BulkCatchChildCatchDetails = Pick<BulkCatchIndividualCatchGridState, 'childCatches' | 'aggregateValue'>;

const BulkCatchIndividualCatchGridContext = createContext<BulkCatchIndividualCatchGridContextValue | undefined>(undefined);

export type BulkCatchIndividualCatchGridProp = {
    taxonomyRequirement: DeclarationSpeciesRequirementDetails;
    childCatches: ChildCatchDetails[];
    onChildCatchesChange: (latestCatchDetail: BulkCatchChildCatchDetails) => void;
};

export const ChildCatchGrid = ({ taxonomyRequirement, childCatches, onChildCatchesChange }: BulkCatchIndividualCatchGridProp) => {
    const [state, setState] = useState<BulkCatchIndividualCatchGridContextValue['state']>({
        childCatches: childCatches,
        taxonomyRequirement: taxonomyRequirement,
        aggregateValue: {},
    });

    useEffect(() => {
        if (taxonomyRequirement.idTaxonomy === state.taxonomyRequirement.idTaxonomy) {
            return;
        }

        setState(prevState => ({ ...prevState, childCatches: [], taxonomyRequirement }));
    }, [taxonomyRequirement]);

    const requirementProperties = taxonomyRequirement.requiredTaxonomyProperties.filter(property => property.isBulkProperty === true);

    if (requirementProperties.length === 0) {
        return <></>;
    }

    const handleAddItem = (newItem: ChildCatchDetails) => {
        setState(prevState => {
            const updatedItems = [...prevState.childCatches, newItem];
            const aggregateValues = calculateAggregateValues(updatedItems);
            const newState = { ...prevState, childCatches: updatedItems, aggregateValue: aggregateValues };
            onChildCatchesChange(state);
            return newState;
        });
    };

    const handleRemoveItem = (removeItem: ChildCatchDetails) => {
        setState(prevState => {
            const updatedItems = prevState.childCatches.filter(item => {
                if (item.tempId && item.tempId === removeItem.tempId) {
                    return false;
                }

                if (removeItem.id && item.id === removeItem.id) {
                    return false;
                }

                return true;
            });
            const aggregateValues = calculateAggregateValues(updatedItems);
            const newState = { ...prevState, childCatches: updatedItems, aggregateValue: aggregateValues };
            onChildCatchesChange(newState);
            return newState;
        });
    };

    const handleUpdateItem = (updatedItem: ChildCatchDetails) => {
        setState(prevState => {
            const updatedItems = prevState.childCatches.map(item => {
                if (item.tempId && item.tempId === updatedItem.tempId) {
                    return updatedItem;
                }

                if (updatedItem.id && item.id === updatedItem.id) {
                    return { ...item, ...updatedItem };
                }

                return item;
            });
            const aggregateValues = calculateAggregateValues(updatedItems);
            const newState = { ...prevState, childCatches: updatedItems, aggregateValue: aggregateValues };
            onChildCatchesChange(newState);
            return newState;
        });
    };

    const calculateAggregateValues = (items: ChildCatchDetails[]): Record<string, number> => {
        const aggregates: Record<string, number> = {};

        items.forEach(item => {
            item.properties.forEach(property => {
                if (!aggregates[property.propertyName as string]) {
                    aggregates[property.propertyName as string] = 0;
                }
                aggregates[property.propertyName as string] += +property.value;
            });
        });
        return aggregates;
    };

    return (
        <BulkCatchIndividualCatchGridContext.Provider value={{ state, handleAddItem, handleRemoveItem, handleUpdateItem }}>
            <Box sx={{ marginLeft: 1, marginBottom: 3, marginTop: 3, border: '1px solid #ccc', padding: 2 }}>
                <Header />
                <List />
                <Footer />
            </Box>
        </BulkCatchIndividualCatchGridContext.Provider>
    );
};

const Header: React.FC = () => {
    const { handleAddItem, state } = useContext(BulkCatchIndividualCatchGridContext)!;
    const [lengthValue, setLengthValue] = useState<number>(0);
    const { t } = useTranslation();

    const lengthProperty = state.taxonomyRequirement.requiredTaxonomyProperties.find(
        (property: DeclarationSpeciesPropertyRequirementDetails) =>
            property.name === DeclarationSpeciesRequirementPropertyName.LENGTH && property.isBulkProperty === true
    );

    if (!lengthProperty) {
        return null;
    }

    const handleAddNew = () => {
        const newChildCatch = {
            tempId: `temp_childDetails_${uuidv4()}` as string,
            properties: [
                {
                    idRequiredTaxonomyProperty: lengthProperty.id,
                    value: lengthValue.toString(),
                    propertyName: lengthProperty.name,
                    dataType: lengthProperty.dataType,
                    unit: lengthProperty.unit!,
                },
            ],
        };

        handleAddItem(newChildCatch);
    };

    const handleValueChange = (newValue: string) => {
        setLengthValue(Number(newValue));
    };

    const lengthField = {
        id: 0,
        type: DYNAMIC_FIELD_TYPE.FLOAT,
        name: lengthProperty.name,
        label: t(`catchDeclaration.catchProperties.${DeclarationSpeciesRequirementPropertyName.LENGTH}`),
        required: true,
        value: lengthValue,
        sx: {
            marginTop: 2,
        },
        unit: lengthProperty.unit,
        unitConfiguration: CatchDeclarationPropertyUnitConfiguration[lengthProperty.name as DeclarationSpeciesRequirementPropertyName],
    } as GridUnitField;

    return (
        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <UnitField
                field={lengthField}
                onChange={function (fieldName: string, newValue: FieldValueType): void {
                    handleValueChange(newValue as string);
                }}
            />
            <Button variant="contained" onClick={handleAddNew} disabled={lengthValue === 0}>
                {t('common.add')}
            </Button>
        </Box>
    );
};

const List = () => {
    const { state, handleRemoveItem, handleUpdateItem } = useContext(BulkCatchIndividualCatchGridContext)!;

    if (state.childCatches.length === 0) {
        return null;
    }

    const onDetailsChange = (newCatchDetails: ChildCatchDetails) => {
        handleUpdateItem(newCatchDetails);
    };

    return (
        <Box sx={{ marginTop: 1, marginBottom: 1.5 }}>
            <Divider sx={{ marginTop: 2.2, marginBottom: 1.5, backgroundColor: 'black', borderWidth: 1.4 }} />
            {state.childCatches.map(item => (
                <ChildCatchItem
                    onRemove={handleRemoveItem}
                    key={item.id ?? item.tempId}
                    catchDetails={item}
                    onDetailsChange={onDetailsChange}
                    taxonomyRequirement={state.taxonomyRequirement}
                />
            ))}
            <Divider sx={{ marginTop: 2, backgroundColor: 'black', borderWidth: 1.4 }} />
        </Box>
    );
};

const Footer: React.FC = () => {
    const { state } = useContext(BulkCatchIndividualCatchGridContext)!;
    const { t } = useTranslation();

    const aggregateValues = state.aggregateValue;

    if (!aggregateValues || Object.keys(aggregateValues).length === 0) {
        return null;
    }

    const countProperty = aggregateValues[DeclarationSpeciesRequirementPropertyName.COUNT];

    if (!countProperty) {
        return null;
    }

    return (
        <div>
            {t(`catchDeclaration.catchProperties.${DeclarationSpeciesRequirementPropertyName.COUNT}`)}: {countProperty.toString()}
        </div>
    );
};
