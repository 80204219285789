import React, { useImperativeHandle, useRef } from 'react';
import {
    DeclarationCatchProperty,
    DeclarationSpeciesRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
    KnownTaxonomyType,
    ChildCatchDetails,
} from '@oma-kala-shared/core/model';

import { useTranslation } from 'react-i18next';
import { GridValue, DYNAMIC_FIELD_TYPE, DynamicGridRef, FieldValueType, GridField } from 'app/components/common/dynamic-grid';

import moment from 'moment';
import { BulkCatchChildCatchDetails, ChildCatchGrid } from './child_catch/ChildCatchGrid';
import CatchDeclarationRequirementHelper, {
    DeclarationPropertyRequirements,
    GenerateFieldsFromRequirementsOptions,
} from 'app/components/declaration/helpers/CatchDeclarationRequirementHelper';
import TaxonomyCatchPropertyGrid from '../../TaxonomyCatchPropertyGrid';
import { StatisticsDetailRef } from '../StatisticsDetail';

type BulkCatchStatisticsDetailProp = {
    catchProperties: DeclarationCatchProperty[];
    declarationTaxonomyRequirement: DeclarationSpeciesRequirementDetails[];
    taxonomyRequirement: DeclarationSpeciesRequirementDetails;
    childCatches: ChildCatchDetails[];
    onValueChange: (latestValue: GridValue) => void;
};

const BulkCatchStatisticsDetail = React.forwardRef<StatisticsDetailRef, BulkCatchStatisticsDetailProp>(
    ({ catchProperties, childCatches, taxonomyRequirement, onValueChange }: BulkCatchStatisticsDetailProp, ref) => {
        const statisticDetailRef = useRef<GridValue | null>({});
        const catchPropertyGridRef = useRef<DynamicGridRef>(null);
        const childCatchGridRef = useRef<BulkCatchChildCatchDetails>({ childCatches: [], aggregateValue: {} });

        const { t } = useTranslation();

        console.log('BulkCatchStatisticsDetail', childCatches);

        const duplicateProperties = CatchDeclarationRequirementHelper.checkDuplicateProperties(
            taxonomyRequirement.requiredTaxonomyProperties
        );

        // Filter out the bulk properties and the duplicate properties
        const bulkCatchRequirementProperties =
            duplicateProperties.length === 0
                ? taxonomyRequirement.requiredTaxonomyProperties
                : taxonomyRequirement.requiredTaxonomyProperties.filter(
                      property => !property.isBulkProperty && !duplicateProperties.includes(property.name)
                  );

        console.log('BulkCatchStatisticsDetail', bulkCatchRequirementProperties, taxonomyRequirement.requiredTaxonomyProperties);

        useImperativeHandle(ref, () => ({
            validate: () => validateStatisticDetail(),
            getValue: () => statisticDetailRef.current,
            getChildCatches: () => childCatchGridRef.current.childCatches,
            getDefaultValues: () => {
                return preDefinedValues;
            },
        }));

        const onChildCatchesChange = (latestCatchDetail: BulkCatchChildCatchDetails) => {
            childCatchGridRef.current = latestCatchDetail;
            const { aggregateValue } = latestCatchDetail;
            handleValueChange(aggregateValue);
        };

        const handleValueChange = (newGridValue: GridValue) => {
            statisticDetailRef.current = {
                ...(statisticDetailRef.current ?? {}),
                ...newGridValue,
            };
            console.log('BulkCatchStatisticsDetail', statisticDetailRef.current, newGridValue);
            validateStatisticDetail();
            onValueChange(statisticDetailRef.current);
        };

        const validateStatisticDetail = () => {
            let isValid = true;

            if (!catchPropertyGridRef.current?.validate()) {
                isValid = false;
            }

            // Bulk catch should have at least 2 child catches
            if (duplicateProperties.length > 0 && (childCatchGridRef.current?.childCatches ?? []).length < 2) {
                isValid = false;
            }

            return isValid;
        };

        const fieldFiltering = (property: DeclarationPropertyRequirements): boolean => {
            return CatchDeclarationRequirementHelper.isStatisticProperty(property.name);
        };

        const visibilityHandler = (property: DeclarationPropertyRequirements): boolean => {
            return true;
        };

        const fieldLabelHandler = (property: DeclarationPropertyRequirements): string => {
            return t(`catchDeclaration.catchProperties.${property.name}`);
        };

        const fieldsPostHandler = (fields: Record<string, GridField>, fieldValues: Record<string, FieldValueType>) => {
            // Update the fields with the catch properties
            const { updatedFields, updatedValues } = CatchDeclarationRequirementHelper.updateFieldsWithCatchProperties(
                fields,
                fieldValues,
                catchProperties
            );

            statisticDetailRef.current = {
                ...statisticDetailRef.current,
                ...updatedValues,
            };

            return updatedFields;
        };

        const preDefinedValues: Record<string, FieldValueType> = {
            [DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT]: 0,
            [DeclarationSpeciesRequirementPropertyName.COUNT]: 2,
            [DeclarationSpeciesRequirementPropertyName.WEIGHT]: 0,
            [DeclarationSpeciesRequirementPropertyName.LENGTH]: 0,
        };

        const predefinedValidationRules: Record<string, string> = {
            [DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT]: 'numeric|min:0',
            [DeclarationSpeciesRequirementPropertyName.COUNT]: 'numeric|min:2',
            [DeclarationSpeciesRequirementPropertyName.WEIGHT]: 'required|greater_than:0',
            [DeclarationSpeciesRequirementPropertyName.LENGTH]: 'required|greater_than:0',
        };

        const options: GenerateFieldsFromRequirementsOptions = {
            requirements: bulkCatchRequirementProperties,
            fieldFiltering,
            visibilityHandler: visibilityHandler,
            fieldLabelHandler,
            predefinedDefaultValues: preDefinedValues,
            predefinedValidationRules,
        };

        return (
            <>
                <ChildCatchGrid
                    taxonomyRequirement={taxonomyRequirement}
                    childCatches={childCatches}
                    onChildCatchesChange={onChildCatchesChange}
                />
                <TaxonomyCatchPropertyGrid
                    ref={catchPropertyGridRef}
                    generateFieldsFromRequirementsOptions={options}
                    fieldsPostHandler={fieldsPostHandler}
                    fieldsSortOrder={[DeclarationSpeciesRequirementPropertyName.COUNT, DeclarationSpeciesRequirementPropertyName.WEIGHT]}
                    onValueChange={handleValueChange}
                />
            </>
        );
    }
);

BulkCatchStatisticsDetail.displayName = 'BulkCatchStatisticsDetail';

export default BulkCatchStatisticsDetail;
