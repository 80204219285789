import React, { useState } from 'react';
import { Modal, Typography, Grid, TextField, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { DeclarationSearchRequest } from '@oma-kala-shared/core/model';

export const getTypeFromQueryParams = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const searchParams = new URLSearchParams(url.search);
    const val = searchParams.get('type');
    return val;
};

export const DefaultDeclarationSearchParams = {
    page: 1,
    size: 50,
    declarationId: null,
    firstName: null,
    lastName: null,
    email: null,
    sequentialId: null,
    isGroup: getTypeFromQueryParams() === 'group',
};

export const DefaultUserDeclarationSearchParams = {
    page: 1,
    size: 50,
    isGroup: getTypeFromQueryParams() === 'group',
    fromDate: '',
    toDate: '',
};

const searchData = ['email', 'sequentialId', 'firstName', 'lastName'];

export type SearchInfo = {
    [key in (typeof searchData)[number]]: string;
};

type FieldInfo = SearchInfo;

export const getSearchInfoFromSearchParams = (searchParams: DeclarationSearchRequest): SearchInfo => {
    const searchInfo: SearchInfo = {};
    for (const [key, value] of Object.entries(searchParams)) {
        if (searchData.includes(key)) {
            searchInfo[key] = value as string;
        }
    }

    return searchInfo;
};

type DeclarationSearchModalProps = {
    open: boolean;
    searchInfo: SearchInfo;
    onClose: (searchParams: DeclarationSearchRequest | null) => void;
};

export const FieldLabelTranslateKey: FieldInfo = {
    firstName: 'common.firstName',
    lastName: 'common.lastName',
    email: 'common.email',
    sequentialId: 'catchDeclaration.catchDeclarationId',
};

const fieldId: FieldInfo = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    sequentialId: 'sequentialId',
};

const DeclarationSearchModal = ({ open, onClose, searchInfo }: DeclarationSearchModalProps) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<SearchInfo>(searchInfo);

    const handleInputChange = (fieldName: string, value: string | number) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldName]: value as string,
        }));
    };

    const handleModalClose = (cancelled: boolean) => {
        // Use DefaultDeclarationSearchParams because now only admin can do search
        onClose(cancelled ? null : { ...DefaultDeclarationSearchParams, ...formData });
    };

    return (
        <Modal
            open={open}
            onClose={() => handleModalClose(true)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t('catchDeclaration.windowText')}
                </Typography>
                <Grid container spacing={3}>
                    {searchData.map((key: string) => {
                        return (
                            <Grid key={key} item xs={6}>
                                <TextField
                                    id={fieldId[key] as string}
                                    label={t(FieldLabelTranslateKey[key]) as string}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    value={formData[key] ?? ''}
                                    onChange={e => handleInputChange(key, e.target.value)}
                                />
                            </Grid>
                        );
                    })}
                    <Button sx={{ mt: 2, ml: 3 }} variant="contained" color="primary" onClick={() => handleModalClose(false)}>
                        {t('common.search')}
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

export default DeclarationSearchModal;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
