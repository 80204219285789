import React, { useImperativeHandle, useRef } from 'react';
import {
    DeclarationFisherProperty,
    DeclarationFisherRequirementPropertyDetails,
    DeclarationFisherRequirementPropertyName,
} from '@oma-kala-shared/core/model';

import { useTranslation } from 'react-i18next';
import { GridValue, DynamicGridRef, FieldValueType, DYNAMIC_FIELD_TYPE } from 'app/components/common/dynamic-grid';
import DynamicGrid from 'app/components/common/dynamic-grid/DynamicGrid';
import CatchDeclarationRequirementHelper, { DeclarationPropertyRequirements, GenerateFieldsFromRequirementsOptions } from '../../../helpers/CatchDeclarationRequirementHelper';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectUserData, selectUserIsAdmin, selectUserIsEditor } from 'app/state/user/userSlice';

type GroupDetailsProps = {
    fisherProperties?: DeclarationFisherProperty[];
    requirementProperties: DeclarationFisherRequirementPropertyDetails[];
    onValueChange: (latestValue: GridValue) => void;
};

type GroupDetailsRef = Omit<DynamicGridRef, 'setError'>;

/**
 * Right now we do not know we have difference requirement for group in API
 * or not so basicly this component will be the same as Personal but the count
 * will be shown
 */
const GroupDetails = React.forwardRef<GroupDetailsRef, GroupDetailsProps>(
    ({ fisherProperties, requirementProperties, onValueChange }: GroupDetailsProps, ref) => {
        const gridValue = useRef<GridValue | null>({});
        const dynamicGridRef = useRef<DynamicGridRef | null>(null);
        const { t } = useTranslation();
        const isAdminOrEditor = useSelector(selectUserIsAdmin) === true || useSelector(selectUserIsEditor) === true;
        const userData = useSelector(selectUserData);

        const handleValueChange = (fieldValues: GridValue) => {
            gridValue.current = {
                ...(gridValue.current ?? {}),
                ...fieldValues,
            };
            onValueChange(gridValue.current);
        };

        useImperativeHandle(ref, () => ({
            validate: () => {
                return dynamicGridRef.current?.validate() as boolean;
            },
            getValue: () => {
                return gridValue.current ?? null;
            },
        }));

        /**
         * For now fisher form only render when we have fisher requirements
         */
        if (requirementProperties.length === 0) {
            return <></>;
        }

        // Need to create fields from requiredFields
        const preDefinedValues: Record<string, FieldValueType> = {
            [DeclarationFisherRequirementPropertyName.STREET_ADDRESS]: isAdminOrEditor ? '' : userData?.addressStreet ?? '',
            [DeclarationFisherRequirementPropertyName.MUNICIPALITY]: isAdminOrEditor ? '' : userData?.municipality ?? '',
            [DeclarationFisherRequirementPropertyName.BIRTH_DATE]: isAdminOrEditor ? '' : userData?.birthDateIso ?? moment().toISOString(),
            [DeclarationFisherRequirementPropertyName.FIRST_NAME]: isAdminOrEditor ? '' : userData?.firstName ?? '',
            [DeclarationFisherRequirementPropertyName.LAST_NAME]: isAdminOrEditor ? '' : userData?.lastName ?? '',
            [DeclarationFisherRequirementPropertyName.COUNT]: 2,
        };

        const visibilityHandler = (property: DeclarationPropertyRequirements): boolean => {
            const hideFields: string[] = [];

            return !hideFields.includes(property.name);
        };

        const fieldLabelHandler = (property: DeclarationPropertyRequirements): string => {
            // let groupPrefix = t('catchDeclaration.fisherGroupFieldPrefix');
            // if (property.name === DeclarationFisherRequirementPropertyName.COUNT) {
            //     groupPrefix = t('catchDeclaration.fisherGroupCount');
            // }
            return `${t(`catchDeclaration.fisherProperties.group.${property.name}`)}`;
        };

        const predefinedValidationRules: Record<string, string> = {
            [DeclarationFisherRequirementPropertyName.COUNT]: 'numeric|min:2',
        };

        // Need to create fields from requiredFields
        const options: GenerateFieldsFromRequirementsOptions = {
            requirements: requirementProperties,
            predefinedDefaultValues: preDefinedValues,
            visibilityHandler,
            predefinedValidationRules,
            fieldLabelHandler,
            disabledFields: isAdminOrEditor
                ? []
                : [
                      DeclarationFisherRequirementPropertyName.STREET_ADDRESS,
                      DeclarationFisherRequirementPropertyName.MUNICIPALITY,
                      DeclarationFisherRequirementPropertyName.BIRTH_DATE,
                      DeclarationFisherRequirementPropertyName.FIRST_NAME,
                      DeclarationFisherRequirementPropertyName.LAST_NAME,
                  ],
        };

        const [fields, fieldValues] = CatchDeclarationRequirementHelper.generateFieldsFromRequirements(options);

        /**
         * Update the ref value
         */
        gridValue.current = {
            ...(gridValue.current ?? {}),
            ...fieldValues,
        };

        // When we have the fisher properties, need to set value to field and fieldValue ref
        if (fisherProperties && fisherProperties.length > 0) {
            fisherProperties.forEach(property => {
                const name = property.propertyName?.toUpperCase() as string;

                if (fields[name]) {
                    let value = property.value;
                    if (property.dataType === DYNAMIC_FIELD_TYPE.DATE) {
                        value = moment.utc(property.value).toISOString();
                    }

                    gridValue.current = {
                        ...(gridValue.current ?? {}),
                        [name]: value,
                    };

                    fields[name].value = value;
                }
            });
        }

        return <DynamicGrid ref={dynamicGridRef} gridFields={[Object.values(fields)]} onValueChange={handleValueChange} />;
    }
);

GroupDetails.displayName = 'FisherGroupDetails';

export default GroupDetails;
