import React, { useState } from 'react';
import { BaseFieldProps, DynamicFieldRef, GridSelectField } from '..';
import { MenuItem, TextField } from '@mui/material';

export type SelectFieldProps = BaseFieldProps & {
    field: GridSelectField;
};

const SelectField = React.forwardRef<DynamicFieldRef, SelectFieldProps>(({ field, onChange, helperText, error }, ref) => {
    const { name, inputSize, value, label, sx, items } = field;
    const [currentValue, setCurrentValue] = useState(value);
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.target.value);
        onChange(name, e.target.value);
    };
    return (
        <TextField
            sx={{
                width: inputSize,
                ...(sx ?? {}),
            }}
            name={name}
            select
            fullWidth={inputSize === undefined}
            label={label}
            error={error}
            helperText={helperText}
            value={currentValue}
            onChange={handleOnChange}
            required={true}
        >
            {items.map(item => (
                <MenuItem key={item.value} value={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </TextField>
    );
});

SelectField.displayName = 'Select Field';

export default SelectField;
