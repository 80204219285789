import { Box, Button } from '@mui/material';
import {
    ChildCatchDetails,
    DeclarationCatchProperty,
    DeclarationSpeciesRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
} from '@oma-kala-shared/core/model';
import React, { createRef } from 'react';

import { GridField, GridValue } from 'app/components/common/dynamic-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SingleCatchStatisticsDetail from '../../single_catch/SingleCatchStatisticDetail';
import { StatisticsDetailRef } from '../../StatisticsDetail';
import { getPropertyNameOnFrontend } from '../../../CatchRegistryPropertyNameMapper';
import CatchDeclarationRequirementHelper from 'app/components/declaration/helpers/CatchDeclarationRequirementHelper';

type ChildCatchItemProp = {
    catchDetails: ChildCatchDetails;
    taxonomyRequirement: DeclarationSpeciesRequirementDetails;
    onDetailsChange: (newCatchDetails: ChildCatchDetails) => void;
    onRemove: (removeCatchDetails: ChildCatchDetails) => void;
};

export const ChildCatchItem = ({ catchDetails, taxonomyRequirement, onDetailsChange, onRemove }: ChildCatchItemProp) => {
    const statisticsGridRef = createRef<StatisticsDetailRef>();

    const requirementProperties = taxonomyRequirement.requiredTaxonomyProperties.filter(property => property.isBulkProperty === true);

    const fieldPostHandler = (field: GridField) => {
        const propertyName = getPropertyNameOnFrontend(field.name);

        if (propertyName === DeclarationSpeciesRequirementPropertyName.LENGTH) {
            Object.assign(field, {
                inputProps: { size: 'small' },
                adjustButtonProps: { sx: { p: 0 } },
                unitSelectorProps: { sx: { border: 'none', paddingRight: '20px' } },
                inputSize: '95%',
                containerProps: {
                    xs: 6,
                },
            });
        }

        if (
            propertyName === DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT ||
            propertyName === DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT
        ) {
            Object.assign(field, {
                containerProps: {
                    sx: {
                        alignSelf: 'center',
                    },
                    xs: 3,
                },
            });
        }

        return field;
    };

    const handleValueChange = (newValue: GridValue) => {
        const properties: DeclarationCatchProperty[] = CatchDeclarationRequirementHelper.generateCatchPropertiesFromGridValue(
            requirementProperties,
            catchDetails.properties ?? [],
            newValue!
        );

        // From grid value to child catch details
        const updatedItem = {
            ...catchDetails,
            properties,
        };
        onDetailsChange(updatedItem);
    };

    const handleRemove = (): void => {
        onRemove(catchDetails);
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '96%' }}>
                    <SingleCatchStatisticsDetail
                        isInsideBulkCatch={true}
                        ref={statisticsGridRef}
                        catchProperties={catchDetails.properties}
                        declarationTaxonomyRequirement={[]}
                        taxonomyRequirement={taxonomyRequirement}
                        onValueChange={handleValueChange}
                        fieldGenerateOptions={{ fieldPostHandler }}
                    />
                </Box>
                <Box sx={{ alignSelf: 'center' }}>
                    <Button
                        onClick={() => handleRemove()}
                        size="small"
                        variant="contained"
                        sx={{ p: 0, minWidth: 4, display: 'inline-block' }}
                        color="error"
                    >
                        <ClearIcon />
                    </Button>
                </Box>
            </Box>
        </>
    );
};
