/* eslint-disable valid-jsdoc */
import React, { useEffect, useRef, useState } from 'react';
import { InputAdornment, MenuItem, TextField, SxProps, TextFieldProps, IconButtonProps } from '@mui/material';

import { convertCommaToDot, displayDecimalValue } from 'app/utils/Utils';
import AddIcon from '@mui/icons-material/Add';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDebounce } from 'app/hooks/useDebounce';
import { DeclarationSpeciesRequirementPropertyName } from '@oma-kala-shared/core/model';
import { BaseFieldProps, GridUnitField, DynamicFieldRef, FieldValueType, DYNAMIC_FIELD_TYPE } from '..';
import UnitConverter, { MeasureStep, UnitEnum } from '@oma-kala-shared/core/logic/UnitConverter';

export type UnitSelectorProps = Pick<SelectProps, 'sx'>; // Custom styles for Select component

export type AdjustButtonProps = Pick<IconButtonProps, 'sx'>; // Custom styles for IconButton components

export type InputProps = Pick<TextFieldProps, 'size'>;

export type UnitFieldProps = BaseFieldProps & {
    field: GridUnitField;
};

const UnitField = React.forwardRef<DynamicFieldRef, UnitFieldProps>(({ field, onChange, helperText, error }) => {
    const { name, inputSize, value, label, sx, unit, unitConfiguration, required, type, inputProps, adjustButtonProps, unitSelectorProps } = field;
    const inputRef = useRef<HTMLInputElement | null>(null);
    const inputCtrlRef = useRef<boolean>(false);

    const size = inputProps?.size ?? 'medium';

    // This should be value store in db (dot as decimal seperator)
    const [currentValue, setCurrentValue] = useState(value.toString());
    // This should be value to show in UI (comma as decimal seperator), needed because of convert unit feature
    const currentValueRef = useRef<string>(value.toString());

    // This is debounced value to prevent it update immidiately
    const debouncedFieldValue = useDebounce(currentValue, 400);

    const [currentUnit, setCurrentUnit] = useState<string | null>(unit);

    const updateFieldValueAndDisplay = (value: number, unit: string, updateRefOnly = false) => {
        const decimalPlaces = getDecimalPlaces(unit);
        setFieldValue(value.toString(), updateRefOnly);
        displayValue(value, decimalPlaces);
    };

    useEffect(() => {
        if (!currentUnit || !unit) {
            return;
        }

        const newValue = convertValueToNewUnit(currentValue, currentUnit, unit);

        // If you want to prevent show 0 value in first rendering, uncomment below
        // if (newValue === 0) {
        //     return;
        // }

        updateFieldValueAndDisplay(newValue, currentUnit);
    }, []);

    const convertValueToNewUnit = (value: string, fromUnit: string, toUnit: string) => {
        const rawValue = convertCommaToDot(value);
        const numberValue = parseFloat(rawValue);

        if (numberValue === 0.0) {
            return 0;
        }

        if (fromUnit === toUnit) {
            return numberValue;
        }

        return UnitConverter.convertValue(numberValue, fromUnit, toUnit);
    };

    useEffect(() => {
        const newValue = convertValueToNewUnit(debouncedFieldValue, currentUnit!, unit!);
        onChange(name, newValue);
    }, [debouncedFieldValue]);

    if (currentUnit === null) {
        return <></>;
    }

    let measure;
    let measureUnits;
    let measureStep: MeasureStep;
    if (!unitConfiguration) {
        // When the unit is changed, we need to update the value and display the new value
        measure = UnitConverter.getMeasureName(currentUnit);
        measureUnits = UnitConverter.getMeasureUnits(measure);
        measureStep = UnitConverter.getMeasureStep(measure);
    } else {
        measureUnits = unitConfiguration.units;
        measureStep = unitConfiguration.step;
    }

    if (!measureUnits || !measureStep) {
        return <></>;
    }

    const baseResolutionUnit: Record<string, UnitEnum> = {
        [DeclarationSpeciesRequirementPropertyName.WEIGHT]: UnitEnum.Grams,
        [DeclarationSpeciesRequirementPropertyName.LENGTH]: UnitEnum.Millimeters,
    };

    /**
     * Get the decimal places for a given unit. If the unit is not
     * one of the base units, return the default decimal places.
     *
     * @param {string} unit - the unit to get the decimal places for
     * @returns {number} the decimal places for the given unit
     */
    const getDecimalPlaces = (unit: string) => {
        const baseUnit = unitConfiguration ? unitConfiguration.baseUnit : baseResolutionUnit[name];
        const defaultDecimalPlaces = 2;

        if (!baseUnit) {
            return defaultDecimalPlaces;
        }

        return UnitConverter.getDecimalPlaces(unit, baseUnit);
    };

    /**
     * Calculate the next value based on the given step and current value
     *
     * Before calculating the next value, the value is converted to the unit is used in step measurement
     * and it will be convert base to current unit after calculation.
     *
     * The reason for it is that we want to prevent the decimal calculation which behaves weird in JS
     * and it can lead to inaccurate results while calculate the remainder
     *
     * @param {string} currentUnit - current unit of the value
     * @param {number} currentValue - current value
     * @param {MeasureStep} measureStep - an object containing the unit and step of the measure
     * @param {boolean} isIncrease - whether the value should be increased or decreased
     * @returns {number} the calculated next value
     */
    const calculateNextValue = (currentUnit: string, currentValue: number, measureStep: MeasureStep, isIncrease: boolean): number => {
        const { unit, step } = measureStep;

        const usedValue = UnitConverter.convertValue(currentValue, currentUnit, unit);
        const usedStep = step;
        const remainder = usedValue % usedStep;

        const nextValue = isIncrease ? usedValue + step - remainder : usedValue - (remainder === 0 ? step : remainder);
        return UnitConverter.convertValue(nextValue, unit, currentUnit);
    };

    const adjustValue = (step: number, isIncrease = true, minValue = 0) => {
        if (step === 0) {
            return;
        }

        const val = parseFloat(convertCommaToDot(currentValueRef.current as string));

        let nextValue = calculateNextValue(currentUnit, val, measureStep, isIncrease);

        if (nextValue < 0) {
            nextValue = minValue;
        }

        updateFieldValueAndDisplay(nextValue, currentUnit);
    };

    const incrementValue = () => {
        adjustValue(measureStep.step, true);
    };

    const decrementValue = () => {
        adjustValue(measureStep.step, false);
    };

    const setFieldValue = (newValue: string, onlyRefValue = false) => {
        currentValueRef.current = newValue;
        if (onlyRefValue) {
            return;
        }

        // This is debounced value to prevent it update immidiately
        setCurrentValue(newValue);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(e.target.value);
    };

    const handleUnitChange = (newUnit: string) => {
        setCurrentUnit(newUnit);

        if (currentValueRef.current.length === 0) {
            return;
        }

        const newValue = convertValueToNewUnit(currentValueRef.current, currentUnit, newUnit);
        updateFieldValueAndDisplay(newValue, newUnit, true);
    };

    /**
     * Because we use ref and component will not rerender so we use inputRef to change value of input
     *
     * @param {string} value value in string
     */
    const displayValue = (value: number, decimalPlaces: number) => {
        inputRef.current!.value = convertToStringValue(value, type, decimalPlaces);
    };

    const convertToStringValue = (value: number, type: DYNAMIC_FIELD_TYPE, decimalPlaces = 2) => {
        let response = '';

        if (type === DYNAMIC_FIELD_TYPE.INTEGER) {
            response = value.toString();
        } else if (type === DYNAMIC_FIELD_TYPE.FLOAT) {
            response = value.toFixed(decimalPlaces);
            response = response.replace('.', ',');
        }

        return response;
    };

    const handleKeyDown = (ev: React.KeyboardEvent) => {
        const isNumberKey = /^\d$/.test(ev.key);
        const commaKey = ',';
        const dotKey = '.';
        const featureKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Home', 'End', 'Delete'];
        const isFeatureKey = featureKeys.includes(ev.key);
        const decimalPlaces = getDecimalPlaces(currentUnit as string);
        const paths = inputRef.current!.value.split(commaKey);
        const isTextSelected = inputRef.current!.selectionEnd! - inputRef.current!.selectionStart!;

        if (isTextSelected && isNumberKey) {
            return true;
        }

        /**
         * It needed for the functions like copy, select all and paste
         */
        if (ev.key === 'Control') {
            inputCtrlRef.current = true;

            setTimeout(() => {
                inputCtrlRef.current = false;
            }, 500);
        }

        if (inputCtrlRef.current === true && !isNumberKey) {
            return true;
        }

        const canInputDecimalSeparator = () => {
            /**
             * When the user press comma, the following cases prevent
             * 1. type of field is integer
             * 2. the input is currently empty
             * 3. the decimalPlaces is 0, so not allow to type comma
             * 4. the input has already 1 comma
             */
            return !(
                type === DYNAMIC_FIELD_TYPE.INTEGER ||
                inputRef.current?.value?.length === 0 ||
                decimalPlaces === 0 ||
                paths.length > 1
            );
        };

        const canInputDigit = () => {
            return !(paths[1] && paths[1].length >= decimalPlaces);
        };

        // Feature keys are always allowed to let user delete
        if (isFeatureKey) return true;

        if (ev.key === dotKey) {
            ev.preventDefault();
            return false;
        }

        if (ev.key === commaKey) {
            if (canInputDecimalSeparator()) {
                return true;
            }

            ev.preventDefault();
            return false;
        }

        if (!isNumberKey) {
            ev.preventDefault();
            return false;
        }

        if (isNumberKey && canInputDigit() === false) {
            ev.preventDefault();
            return false;
        }

        return true;
    };

    return (
        <TextField
            label={label}
            inputRef={inputRef}
            id="filled-start-adornment"
            variant="outlined"
            sx={{
                '& .MuiInputBase-root': { padding: 0 },
                width: inputSize,
                ...(sx ?? {}),
            }}
            size={size}
            onKeyDown={handleKeyDown}
            autoComplete="off"
            margin="normal"
            error={error}
            helperText={helperText}
            onChange={e => handleInputChange(e as React.ChangeEvent<HTMLInputElement>)}
            // onBlur={e => unitFormat(e as React.ChangeEvent<HTMLInputElement>)}
            required={required === true}
            InputLabelProps={{ shrink: !!currentValue }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton size={size} sx={adjustButtonProps?.sx} aria-label="directions" onClick={decrementValue}>
                            <RemoveIcon />
                        </IconButton>
                        <IconButton size={size} sx={adjustButtonProps?.sx} aria-label="directions" onClick={incrementValue}>
                            <AddIcon />
                        </IconButton>
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentUnit}
                            onChange={e => handleUnitChange(e.target.value as string)}
                            sx={{ border: 'none', ...unitSelectorProps?.sx }}
                            size={size}
                        >
                            {measureUnits.map((value, key) => (
                                <MenuItem key={key} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </InputAdornment>
                ),
                ...inputProps,
            }}
        />
    );
});

UnitField.displayName = 'Select Field';

export default UnitField;
