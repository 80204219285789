import React, { useImperativeHandle, useRef, useState } from 'react';
import {
    DeclarationCatchProperty,
    DeclarationSpeciesRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
    FishingType,
    KnownTaxonomyType,
} from '@oma-kala-shared/core/model';

import { useTranslation } from 'react-i18next';
import {
    GridValue,
    GridField,
    DYNAMIC_FIELD_TYPE,
    DynamicGridRef,
    SelectFieldItem,
    GridSelectField,
    FieldValueType,
} from 'app/components/common/dynamic-grid';
import DynamicGrid from 'app/components/common/dynamic-grid/DynamicGrid';

import moment from 'moment';
import CatchDeclarationRequirementHelper, {
    DeclarationPropertyRequirements,
    GenerateFieldsFromRequirementsOptions,
} from 'app/components/declaration/helpers/CatchDeclarationRequirementHelper';

type FishingTripDetailProps = {
    catchProperties: DeclarationCatchProperty[];
    declarationTaxonomyRequirement: DeclarationSpeciesRequirementDetails[];
    taxonomies: SelectFieldItem[];
    taxonomyRequirement: DeclarationSpeciesRequirementDetails;
    onTaxonomyChanged: (taxonomy: DeclarationSpeciesRequirementDetails) => void;
};

export type FishingTripDetailsRef = Omit<DynamicGridRef, 'setError'>;

export const SpeciesFieldName = 'Species';

const FishingTripDetail = React.forwardRef<FishingTripDetailsRef, FishingTripDetailProps>(
    (
        { catchProperties, declarationTaxonomyRequirement, taxonomies, taxonomyRequirement, onTaxonomyChanged }: FishingTripDetailProps,
        ref
    ) => {
        const gridValue = useRef<GridValue | null>({});
        const dynamicGridRef = useRef<DynamicGridRef | null>(null);
        const { t } = useTranslation();
        const requirementProperties = taxonomyRequirement.requiredTaxonomyProperties;

        const handleValueChange = (newGridValue: GridValue) => {
            gridValue.current = {
                ...(gridValue.current ?? {}),
                ...newGridValue,
            };

            const selectedSpecied = gridValue.current[SpeciesFieldName];
            const selectedTaxonomy = declarationTaxonomyRequirement.find(taxonomy => taxonomy.idTaxonomy === selectedSpecied);
            onTaxonomyChanged(selectedTaxonomy!);
        };

        useImperativeHandle(ref, () => ({
            validate: () => {
                return dynamicGridRef.current?.validate() as boolean;
            },
            getValue: () => {
                return gridValue.current ?? null;
            },
        }));

        /**
         * For now fisher form only render when we have fisher requirements
         */
        if (declarationTaxonomyRequirement.length === 0) {
            return <></>;
        }

        const catchGearProperty = catchProperties.find(
            property => property.propertyName === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR
        );

        const isGillNettingGear = catchGearProperty?.value === FishingType.GILLNETTING;

        const catchGearPropertiesName = [
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT,
            DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH,
        ];

        const getFieldTypeForProperty = (property: DeclarationPropertyRequirements): DYNAMIC_FIELD_TYPE => {
            if (property.name === (DeclarationSpeciesRequirementPropertyName.CATCH_GEAR as string)) {
                return DYNAMIC_FIELD_TYPE.SELECT;
            }

            return property.dataType as DYNAMIC_FIELD_TYPE;
        };

        const fieldFiltering = (property: DeclarationPropertyRequirements): boolean => {
            const detailsRequirements: string[] = [
                DeclarationSpeciesRequirementPropertyName.CATCH_DATE,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH,
            ];

            return detailsRequirements.includes(property.name);
        };

        const visibilityHandler =
            (catchProperties: DeclarationCatchProperty[]) =>
            (property: DeclarationPropertyRequirements): boolean => {
                // if (taxonomyRequirement.idTaxonomy === KnownTaxonomyType.PINK_SALMON) {
                //     const visibleFields = [DeclarationSpeciesRequirementPropertyName.CATCH_DATE];
                //     return visibleFields.includes(property.name as DeclarationSpeciesRequirementPropertyName);
                // }

                // If the gear is not gillnetting, we don't need to show the gear properties
                if (catchGearPropertiesName.includes(property.name as DeclarationSpeciesRequirementPropertyName)) {
                    return isGillNettingGear;
                }

                return true;
            };

        // const preDefinedValues: Record<string, FieldValueType> =
        //     isGillNettingGear === false
        //         ? {}
        //         : {
        //               [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING]: 50,
        //               [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT]: 1.5,
        //               [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH]: 10,
        //               [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH]: 0.12,
        //               [DeclarationSpeciesRequirementPropertyName.CATCH_DATE]: moment().startOf('day').utc().toISOString(),
        //           };

        const fieldPostHandler = (field: GridField): GridField => {
            if (field.name === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR) {
                const items = Object.values(FishingType).map(value => {
                    return {
                        value: value,
                        label: t(`common.fishingType.${value}`),
                    };
                });
                (field as GridSelectField).items = items;
                field.sx = {
                    marginTop: 2,
                };
            }

            if (field.name === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING) {
                field.sx = {
                    marginTop: 2,
                };
            }

            return field;
        };

        const fieldLabelHandler = (property: DeclarationPropertyRequirements): string => {
            return t(`catchDeclaration.catchProperties.${property.name}`);
        };

        const predefinedValidationRules: Record<string, string> =
            isGillNettingGear === false
                ? {}
                : {
                      [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING]: 'numeric|min:1',
                      [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH]: 'numeric|min:1',
                      [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT]: 'numeric|min:0.1',
                      [DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH]: 'numeric|min:0.1',
                  };

        const options: GenerateFieldsFromRequirementsOptions = {
            requirements: requirementProperties,
            fieldFiltering,
            visibilityHandler: visibilityHandler(catchProperties),
            fieldTypeHandler: getFieldTypeForProperty,
            predefinedValidationRules,
            fieldPostHandler,
            fieldLabelHandler,
        };

        // Need to create fields from requiredFields
        const [fields, fieldValues] = CatchDeclarationRequirementHelper.generateFieldsFromRequirements(options);

        /**
         * Update the ref value
         */
        gridValue.current = {
            ...(gridValue.current ?? {}),
            ...fieldValues,
        };

        console.log('gridValue', gridValue.current);

        // When we have the catch properties, need to set value to field and fieldValue ref
        if (catchProperties.length > 0) {
            catchProperties.forEach(property => {
                const name = property.propertyName?.toUpperCase() as string;

                if (fields[name]) {
                    let value = property.value;
                    if (property.dataType === DYNAMIC_FIELD_TYPE.DATE) {
                        value = moment.utc(property.value).toISOString();
                    }

                    gridValue.current = {
                        ...(gridValue.current ?? {}),
                        [name]: value,
                    };

                    fields[name].value = value;
                }
            });
        }

        // Need to stimulate the species select for dynamic grid
        const speciesField = {
            id: 0,
            type: DYNAMIC_FIELD_TYPE.SELECT,
            containerProps: {
                xs: 6,
            },
            name: SpeciesFieldName,
            label: t('catchDeclaration.catchProperties.SPECIES'),
            required: true,
            value: taxonomyRequirement.idTaxonomy,
            inputSize: '98%',
            items: taxonomies,
            validationRule: CatchDeclarationRequirementHelper.getValidationRuleForType('select'),
            sx: {
                marginTop: 2,
            },
        };

        const gridFields: GridField[] = [speciesField, ...Object.values(fields)];

        return <DynamicGrid ref={dynamicGridRef} gridFields={[gridFields]} onValueChange={handleValueChange} />;
    }
);

FishingTripDetail.displayName = 'CatchDetails';

export default FishingTripDetail;
