import {
    DeclarationCatchValidationError,
    DeclarationFisherRequirementPropertyName,
    DeclarationRegulation,
    FishingType,
    KnownGearType,
    KnownGearTypeProperty,
    KnownTaxonomyType,
    LayerCommonSettingName,
    MapLayer,
    TaxonomyTypePropertyName,
} from '@oma-kala-shared/core/model';
import { ResourceLanguage } from 'i18next';

import { DrawerFolderName } from 'app/components/layout/drawer/types';

/* eslint-disable max-len */
import { MessageType, NotificationStatus, OmaKalaLanguageCode, OmaKalaUserRole, RedirectView, RequestStatus } from 'app/model';
import { RouteName } from 'app/model/Route';

import { Translations } from './translations';

const translations: Translations & ResourceLanguage = {
    translations: {
        admin: {
            notifications: {
                browse: {
                    title: 'Existing push notifications',
                    description: 'Below you can see existing push notifications, along with their statuses.',
                    helperText:
                        'Expand the push notification to further see the statuses for each message in different languages, along with any details associated with them.',
                    loaded: 'Push notifications loaded successfully',
                    loadError: 'Failed to load push notifications - please try again. {{ message }}.',
                    columns: {
                        createdAt: 'Created at',
                        redirectView: 'Redirect view',
                        redirectId: 'Redirect ID',
                        language: 'Language',
                        status: 'Status',
                        title: 'Title',
                        message: 'Message',
                    },
                },
                send: {
                    buttons: {
                        submit: 'Send',
                    },
                    description: 'Send a new push notification to users in Omakala.',
                    helperText:
                        'This notification is sent to users who have selected $t(translations:common:languages:{{ lang }}) as their language in Omakala.',
                    input: {
                        redirectToViewLabel: 'Link the user to a specific page in the app (optional).',
                        messageLabel: 'Message',
                        redirectViewLabel: 'Link to view',
                        redirectViewIdLabel: 'Link to view ID',
                        roleGroupLabel: 'Role groups',
                        titleLabel: 'Title',
                    },
                    notificationSent: 'Notification sent successfully.',
                    notificationSendingError: 'Something went wrong when sending the notification - please try again.',
                    redirectViews: {
                        [RedirectView.NEWS]: 'News',
                    },
                    requiredValuesMissing: 'Some required values are missing.',
                    title: 'Send Push Notification',
                },
                status: {
                    [NotificationStatus.CREATED]: 'Created',
                    [NotificationStatus.FAILED]: 'Failed',
                    [NotificationStatus.SENT]: 'Sent',
                    [NotificationStatus.IN_PROGRESS]: 'In progress',
                },
                redirectView: {
                    [RedirectView.NEWS]: 'News',
                },
            },
        },
        common: {
            add: 'Add',
            yes: 'Yes',
            no: 'No',
            clear: 'Clear',
            ok: 'Ok',
            close: 'Close',
            address: {
                country: 'Country',
                placeOfResidence: 'Place of residence',
                postalCode: 'Postal code',
                postalTown: 'Postal town',
                street: 'Street address',
                address: 'Address',
            },
            confirmPassword: 'Confirm Password',
            email: 'E-mail',
            firstName: 'First name',

            send: 'Send',
            search: 'Search',
            login: 'Sign in',
            loginDelimiterText: 'Or',
            loginWithSvk: 'Log in with your SVK account',
            svkTempIssue: {
                title: 'Warning',
                content:
                    'For now, the SVK login option is only available to SVK members who do not already have an Omakala account.\n\nSVK members who already have an Omakala account are instructed to use it when logging into Omakala.\n\nIn the future, this restriction will be removed and all SVK members will be able to log in with their SVK IDs.',
                cancel: 'Cancel',
                continue: "Understand, don't show anymore",
                ok: 'Ok',
            },
            logout: 'Log out',
            loginError: 'The email or the password credentials are incorrect. Please re-enter your credentials and try again.',
            save: 'Save',
            cancel: 'Cancel',
            delete: 'Delete',
            mandatory: 'required',
            optional: 'optional',
            back: 'Back',
            date: 'Date',
            birthDate: 'Birtdate',
            languages: {
                [OmaKalaLanguageCode.EN]: 'English',
                [OmaKalaLanguageCode.SV]: 'Swedish',
                [OmaKalaLanguageCode.FI]: 'Finnish',
                [OmaKalaLanguageCode.SE]: '#missing#',
            },
            loadMessage: {
                [RequestStatus.Failed]: 'Could not load content',
                [RequestStatus.Loading]: 'Loading content...',
                [RequestStatus.NotInitiated]: 'Loading has not been initiated',
                [RequestStatus.Successful]: 'Loading succesful',
            },
            lastName: 'Surname',

            mobile: {
                appStore: {
                    linkAltText: 'App Store app link button',
                    qrCodeAltText: 'App Store QR Code',
                },
                playStore: {
                    linkAltText: 'Play Store app link button',
                    qrCodeAltText: 'Play Store QR Code',
                },
            },
            name: 'Name',
            or: 'OR',
            password: 'Password',
            passwordRules:
                'Your password must be at least {{ minLength }} characters long and must include uppercase and lowercase letters, numbers, and special characters.',
            passwordsDoNotMatch: 'The passwords do not match',
            requiredHelpText: 'This value is required',
            phoneNumber: 'Phone number',
            reload: 'Reload',
            return: 'Return',
            roles: {
                [OmaKalaUserRole.ADMIN]: 'Administrator',
                [OmaKalaUserRole.RECREATIONAL_FISHER]: 'Recreational Fisher',
                [OmaKalaUserRole.EDITOR]: 'Editor',
                [OmaKalaUserRole.ORGANIZER]: 'Organizer',
                [OmaKalaUserRole.SVK_USER]: 'Svk-user',
            },
            submit: 'Submit',
            error: 'An unexpected error occurred',
            fishingType: {
                [FishingType.HOOK_AND_LINE]: 'Hook and line',
                [FishingType.ANGLING]: 'Casting with a reel',
                [FishingType.FYKE_NETTING]: 'Fyke netting',
                [FishingType.GILLNETTING]: 'Gillnetting',
                [FishingType.ICE_FISHING]: 'Ice fishing',
                [FishingType.KATISKA]: 'Katiska trapping',
                [FishingType.LONGLINE]: 'Longline fishing',
                [FishingType.LURE_FISHING]: 'Fly fishing',
                [FishingType.TROLLING]: 'Trolling',
                [FishingType.SEINE_NETTING]: 'Seine fishing',
            },
            form: {
                leavingNotification: 'Are you sure you want to leave ? All data will be lost if you did not save or submit',
            },
            dialog: {
                messageType: {
                    [MessageType.INFO]: 'Note',
                    [MessageType.ERROR]: 'Warning',
                    [MessageType.SUCCESS]: 'Success',
                },
                doNotShowAgain: "Don't show this again",
            },
        },
        passwordProperties: {
            forgotPassword: 'Forgot password?',
            validation: {
                description: 'Password should contain:',
                digit: 'Number',
                length: 'At least {{ minLength }} characters',
                lowercase: 'Lowercase letter',
                special: 'Special character',
                uppercase: 'Uppercase letter',
                passwordConfirm: 'Password and password confirmation should match',
                isNewPasswordDifferentThanNewOne: 'New password is not the same as the current one',
            },
            messages: {
                noUserFound: 'No user connected to your social security number was found.',
                identificationUnsuccessful: 'Identification was unsuccessful. Please, try again.',
                passwordChanged: 'Your password has been successfully changed.',
                passwordNotChanged: 'Error while changing your password. Please, try again.',
            },
        },
        header: {
            navigation: {
                news: 'News',
                feedback: 'Feedback',
                sendNotification: 'Send',
                browseNotifications: 'Browse',
            },
        },
        footer: {
            mainFooter: {
                omaKalaLinks: {
                    about: 'Information on the service',
                    terms: 'Terms of use',
                    privacyStatement: 'Privacy statement',
                    technicalRequirements: 'Technical requirements',
                    cmsLogin: 'CMS management tool',
                    feedback: 'Give feedback',
                },
                links: {
                    link: 'Links',
                    privacyStatement: 'Privacy statement',
                    teamsLink: 'Teams discussion group for Finnish Archipelago Sea pilot',
                },
                lukeLinks: {
                    resourceCenter: 'The Natural Resources Institute',
                    contact: 'Contact',
                    media: 'Media',
                },
            },
            secondaryFooter: {
                gov: 'The Natural Resources Institute',
                access: 'Accessibility statement',
                support: 'Technical support and feedback',
            },
        },
        home: {
            registrationInfo: 'Registration is done in the mobile app',
            title: 'Home',
        },
        feedback: {
            title: 'Feedback',
            header: 'Feedback on the service',
            helpText:
                'Give feedback on any problems you encounter within the service or give suggestions for improvements. Contact information is optional and will only be used to contact you for clarifications regarding your feedback.',
            feedback: 'Feedback',
            successMessage: 'Your feedback has been sent.',
            errorMessage: 'Error sending feedback: {{ message }}',
            captcha: 'Captcha',
            captchaMessage:
                'You can send the feedback, when you have written the captcha text. Captcha is not needed for users that are logged in.',
            backendUnavailableMessage:
                'There was a problem establishing connection with the server. Some functionality may not work properly.',
            email: 'You can aslo give feedback by e-mail on: ',
        },
        profile: {
            title: 'My profile',
            name: 'Name',
            alias: 'Alias',
            email: 'E-mail',
            address: 'Address',
            city: 'City',
            postalCode: 'Zip / Postal code',
            placeOfResidence: 'Place of residence',
            phoneNumber: 'Phone number',
            successMessage: 'Profile updated',
            errorMessage: 'Error updating profile: {{ message }}',
            avatarIconAlt: 'Profile menu',
            profileSections: {
                primaryInfo: {
                    title: 'Primary information',
                    description: 'View and edit your primary information',
                },
                changePassword: {
                    title: 'Change password',
                    description: 'You will be logged out after changing your password',
                    externalIdentity: 'You can change your password by visiting {{ provider }} website',
                },
                userConsent: {
                    title: 'User consents',
                },
                svkInfo: {
                    title: 'SVK-Information',
                    membership: {
                        title: 'Membership information (updated on {{time}})',
                        dataUpdateHint:
                            'The membership data is updated at the time of each login using SVK identity. Next time Omakala receives updated information is after logout and then login',
                        expiredDateInAvailable: 'The expiration date of the membership is not available',
                        validUntil: 'The expiration date of the membership: {{time}}',
                    },
                },
            },
        },
        news: {
            title: 'News',
            moreNews: 'More news',
            noMoreNews: 'No more news found.',
            noExist: 'News is not exist.',
        },
        cookieDialog: {
            cookieConfiguration: 'Cookie Configuration',
            description: 'Omakala uses cookies for different purposes. In order to use the web site, you need to accept the cookies. ',
            essentialCookie:
                'We use cookies for the web site functionality and safety in functions such as login and content management. Cookies are essential and are save always automatically.',
            analyticCookie:
                'Analytic cookies are used to track site usage and load. Analytics information is used to maintain and develop the site. In addition cookies are used according to Google Analytics cookie usage policy.',
            privacyLink: 'Privacy statement',
        },
        routes: {
            [RouteName.ABOUT]: 'About',
            [RouteName.FEEDBACK]: 'Feedback',
            [RouteName.HOME]: 'Home',
            [RouteName.NEWS]: 'News',
            [RouteName.NOTIFICATIONS]: 'Notifications',
            [RouteName.PROFILE]: 'Profile',
            [RouteName.SEND_NOTIFICATION]: 'Send notifications',
            [RouteName.TECHNICAL_REQUIREMENTS]: 'Technical Requirements',
            [RouteName.TERMS_AND_CONDITION]: 'Terms & Conditions',
            [RouteName.CMS_ITEM]: 'CMS Item',
            [RouteName.NEWS_ITEM]: 'Article',
            [RouteName.LOGIN]: 'Login',
            [RouteName.RESET_PASSWORD]: 'Reset Password',
            [RouteName.REGISTRATION]: 'Register New Account',
            [RouteName.GEAR]: 'Gear',
            [RouteName.GEAR_ITEM]: 'Gear Detail',
            [RouteName.CREATE_CATCH_DECLARATION]: 'Create new Catch Declaration',
            [RouteName.CATCH_DECLARATION_GROUP]: 'Catch declarations {{- newline}} - Organizer',
            [RouteName.CATCH_DECLARATION_PERSONAL]: 'Catch declarations {{- newline}} - Personal',
            [RouteName.CATCH_DECLARATION]: 'Catch declarations',
            [RouteName.SUPPORT]: 'Support',
            [RouteName.PRIVACY_POLICY]: 'Privacy policy',
        },
        drawerMenu: {
            folders: {
                [DrawerFolderName.LINKS]: 'Links',
                [DrawerFolderName.LUKE]: 'Luke',
                [DrawerFolderName.SERVICE_INFORMATION]: 'Service Information',
                [DrawerFolderName.CATCH_DECLARATION]: 'Catch declaration',
                [DrawerFolderName.ADMIN]: 'Admin',
                [DrawerFolderName.SUPPORT_AND_FEEDBACK]: 'Support & Feedback',
                [DrawerFolderName.PUSH_NOTIFICATIONS]: 'Push-notifications',
                [DrawerFolderName.ACCOUNT]: 'Account',
            },
        },
        registration: {
            title: 'Registration',
            info: 'Registering requires strong authentication (Suomi.fi).',
            helpText: 'Click "Continue" to be transferred to the Suomi.fi service.',
            acceptTermsAndConditions: 'I accept the',
            termsAndConditions: 'Terms and Conditions',
            registration: 'Registration',
            alradyHaveAccount: 'You already have an account',
            identificationUnsuccessful: 'Identification unsuccessful',
            validation: {
                email: {
                    format: 'Wrong email format',
                    empty: 'Email can not be empty',
                },
            },
            messages: {
                registrationSuccessful: 'Registration successful! Now you can log in.',
                registrationUnsuccessful: 'Registration unsuccessful, please try again later',
            },
            sections: {
                primaryInfo: {
                    title: 'Primary information',
                    description: 'Provide primary information',
                },
                contactInfo: {
                    title: 'Contact information',
                    description: 'Provide contact information',
                },
                password: {
                    title: 'Password',
                    description: 'Provide secure password',
                },
            },
        },
        gear: {
            addNewGear: 'Add new gear',
            titleMyGear: 'My Gear',
            titleNewGear: 'New Gear',
            titleEditGear: 'Edit Gear',
            titleGearType: 'Type of Gear',
            gearsNotFound: 'No registered gears found',
            deletegearTitle: 'Delete Gear?',
            allGear: 'All gears',
            deleteGearMessage:
                'The deleted gear will still be preserved for the events for which it is currently selected, but you will not be able to edit the gear or select it for new events.',
            editPropertyHeader:
                'What is the $t(translations:gear:gearTypeProperty:{{ propertyName }}:name) of your $t(translations:gear:gearType:{{ gearType }}:name)?',
            gearIconAccessibilityLabel: 'Gear icon',
            gearType: {
                [KnownGearType.ROD]: {
                    name: 'fishing rod',
                    possessiveName: 'fishing rod',
                    defaultGearName: 'My fishing rod',
                },
                [KnownGearType.NET]: {
                    name: 'net',
                    possessiveName: 'net',
                    defaultGearName: 'My net',
                },
                [KnownGearType.TRAP]: {
                    name: 'trap',
                    possessiveName: 'trap',
                    defaultGearName: 'My trap',
                },
                [KnownGearType.KATISKA]: {
                    name: 'katiska',
                    possessiveName: 'katiska',
                    defaultGearName: 'My katiska',
                },
                [KnownGearType.FYKE]: {
                    name: 'fyke',
                    possessiveName: 'fyke',
                    defaultGearName: 'My fyke',
                },
                [KnownGearType.SPINNING_ROD]: {
                    name: 'spinning rod',
                    possessiveName: 'spinning rod',
                    defaultGearName: 'My spinning rod',
                },
                [KnownGearType.FLY_ROD]: {
                    name: 'fly rod',
                    possessiveName: 'fly rod',
                    defaultGearName: 'My fly rod',
                },
                [KnownGearType.TROLLING_ROD]: {
                    name: 'trolling rod',
                    possessiveName: 'trolling rod',
                    defaultGearName: 'My trolling rod',
                },
                [KnownGearType.SEINE]: {
                    name: 'seine',
                    possessiveName: 'seine',
                    defaultGearName: 'My seine',
                },
                [KnownGearType.SINGLE_HOOK_AND_LINE]: {
                    name: 'single hook and line',
                    possessiveName: 'single hook and line',
                    defaultGearName: 'My single hook and line',
                },
                [KnownGearType.SPRING_LOADED_FISH_HOOK]: {
                    name: 'spring loaded fish hook',
                    possessiveName: 'spring loaded fish hook',
                    defaultGearName: 'My spring loaded fish hook',
                },
                [KnownGearType.OTHER_HOOK_GEAR]: {
                    name: 'other hook',
                    possessiveName: 'other hook',
                    defaultGearName: 'Other hook',
                },
                [KnownGearType.LONG_LINE]: {
                    name: 'longline',
                    possessiveName: 'long line',
                    defaultGearName: 'My longline',
                },
                [KnownGearType.ICE_ROD]: {
                    name: 'ice rod',
                    possessiveName: 'ice rod',
                    defaultGearName: 'My ice rod',
                },
                [KnownGearType.OTHER]: {
                    name: 'other',
                    possessiveName: 'other',
                    defaultGearName: 'Other',
                },
            },
            gearTypeProperty: {
                [KnownGearTypeProperty.HEIGHT]: {
                    name: 'height',
                },
                [KnownGearTypeProperty.LENGTH]: {
                    name: 'length',
                },
                [KnownGearTypeProperty.LENGTH_SEINE_BAG]: {
                    name: 'siene bag length',
                },
                [KnownGearTypeProperty.NODE_SPACING]: {
                    name: 'mesh size',
                },
                [KnownGearTypeProperty.NODE_SPACING_FYKE_BAG]: {
                    name: 'fyke bag mesh size',
                },
                [KnownGearTypeProperty.NODE_SPACING_SEINE_BAG]: {
                    name: 'seine bag mesh size',
                },
                [KnownGearTypeProperty.WIRE_STRENGTH]: {
                    name: 'twine strength',
                },
                [KnownGearTypeProperty.HOOK_COUNT]: {
                    name: 'hook count',
                },
                [KnownGearTypeProperty.DESCRIPTION]: {
                    name: 'description',
                },
            },
            gearDeleted: 'Gear deleted',
            gearUpdated: 'Gear updated',
            gearSaved: 'Gear saved',
            callFailed: 'The request failed. Please try again.',
        },
        about: {
            pageTitle: 'About Omakala',
            downloadAndroid: 'Download Omakala for Android',
            downloadIos: 'Download Omakala for IOS',
        },
        registrationPrompt: {
            text: 'In order to continue, you need to first identify yourself at suomi.fi and after that register an Omakala account. You will be redirect to identification in 5 seconds.',
        },
        errorDuringSvkLogin: {
            title: 'Login with SVK credentials failed. Please, be in touch with omakala@luke.fi.',
        },
        unknownSvkLoginError: {
            title: 'Unknown login error. Pelase, be in touch with omakala@luke.fi',
        },
        catchDeclaration: {
            validation: {
                greaterThan: ':attribute must be greater than :greater_than',
            },
            list: {
                searchCondition: 'Catch declarations based on search criteria(s):',
                showAll: 'All declarations',
                title: {
                    group: 'Catch declarations - Fishing trip organizer',
                    personal: 'Catch declarations - Personal',
                },
            },
            generalizedLocation: {
                generalizing: 'Generalizing your selected location',
                generalizedError: 'The generalization of your location was unsuccessful for some reason',
            },
            type: {
                group: 'Group declaration',
                personal: 'Personal declaration',
            },
            FIRST_NAME: 'First name',
            LAST_NAME: 'Last name',
            BIRTH_DATE: 'Birth date',
            STREET_ADDRESS: 'Street address',
            MUNICIPALITY: 'Municipality',
            COUNT: 'Number of fishers',
            fishIcon: 'Fish icon',
            userId: 'User ID',
            catchDeclarationId: 'Declaration ID',
            windowText: 'Search by declaration parameter',
            species: 'Species',
            location: 'Catch location',
            fishingMethod: 'Fishing method',
            fisherGroupCount: 'Group',
            primaryInfo: {
                title: 'Catch declarations',
                description: 'View and edit catch declarations',
            },
            newDeclaration: {
                title: {
                    group: 'New declaration - Fishing trip organizer',
                    personal: 'New declaration - Personal',
                },
                fisherInfoTitle: "Reporter's information",
                createNewCatch: 'New catch',
                catchInfoTitle: "Catch's information",
                catchListTitle: 'Catches',
                submitDeclaration: 'Submit catch declaration',
                additionalInfo: 'Additional information',
                messages: {
                    validateErrorMessage: 'There is something wrong please check again',
                    releaseCountGreaterThanCountMessage: 'The released amount cannot be greater than total amount',
                    adiposeFinCutCountGreaterThanCountMessage: 'Adipose fin amount can not be greater than total amount',
                    invalidAdiposeFinRealeaseCountMessage:
                        'The adipose fin released amount cannot be greater than released amount or adipose fin amount',
                },
                declarationCatchValidationError: {
                    [DeclarationCatchValidationError.DUPLICATE_CATCH]: 'Catch is identical to a previously added catch',
                    [DeclarationCatchValidationError.INVALID_REQUIREMENT_ID_REFERENCE]:
                        'Invalid id reference - please contact omakala@luke.fi',
                    [DeclarationCatchValidationError.INVALID_CATCH_REFERENCE_ID_FORMAT]:
                        'Invalid requirement reference - please contact omakala@luke.fi',
                    [DeclarationCatchValidationError.INVALID_DATE_FORMAT]: 'Invalid date format - please contact omakala@luke.fi',
                },
            },
            editDeclaration: {
                title: 'Edit catch declaration',
                saveDeclaration: 'Save catch declaration',
                editText:
                    'This catch has already been declared to the catch register. If you want to change any information related to the declaration, please contact the Natural Resources Institute Finland. (Luke)',
            },
            fisherProperties: {
                personal: {
                    [DeclarationFisherRequirementPropertyName.FIRST_NAME]: 'First name',
                    [DeclarationFisherRequirementPropertyName.LAST_NAME]: 'Last name',
                    [DeclarationFisherRequirementPropertyName.MUNICIPALITY]: 'Municipality',
                    [DeclarationFisherRequirementPropertyName.BIRTH_DATE]: 'Birthdate',
                    [DeclarationFisherRequirementPropertyName.STREET_ADDRESS]: 'Street address',
                    [DeclarationFisherRequirementPropertyName.COUNT]: 'Number of people',
                },
                group: {
                    [DeclarationFisherRequirementPropertyName.FIRST_NAME]: '$t(catchDeclaration.fisherGroupFieldPrefix) first name',
                    [DeclarationFisherRequirementPropertyName.LAST_NAME]: '$t(catchDeclaration.fisherGroupFieldPrefix) last name',
                    [DeclarationFisherRequirementPropertyName.MUNICIPALITY]: '$t(catchDeclaration.fisherGroupFieldPrefix) municipality',
                    [DeclarationFisherRequirementPropertyName.BIRTH_DATE]: '$t(catchDeclaration.fisherGroupFieldPrefix) birthdate',
                    [DeclarationFisherRequirementPropertyName.STREET_ADDRESS]: '$t(catchDeclaration.fisherGroupFieldPrefix) street address',
                    [DeclarationFisherRequirementPropertyName.COUNT]: "Number of group's member",
                },
            },
            catchProperties: {
                WEIGHT: 'Total weight',
                LENGTH: 'Length',
                CATCH_DATE: 'Catch date',
                CATCH_GEAR: 'Catch method',
                CATCH_GEAR_NODE_SPACING: 'Mesh size',
                CATCH_GEAR_HEIGHT: 'Net height',
                CATCH_GEAR_LENGTH: 'Net length',
                CATCH_GEAR_WIRE_STRENGTH: 'Twine strength',
                COUNT: 'Bulk amount',
                RELEASE_COUNT: 'Release amount',
                ADIPOSE_FIN_CUT_COUNT: 'Number of individuals with adipose fin',
                ADIPOSE_FIN_CUT_RELEASE_COUNT: 'Number of released individuals with adipose fin',
                SPECIES: 'Species',
            },
            fetchMoreRows: 'View more',
            addNew: 'Add new',
            resultsFound: 'results found',
            noResultsFound: 'No results found',
            source: 'Source',
            automatic: 'Mobile, automatic declaration',
            manual: 'Mobile, manual declaration',
            webform: 'Web page',
            newLocationSetInfo: 'Catch location has been changed. Generalized location will be updated after saving',
            noLocationSetInfo: 'Select location on the map. It will be generalized after saving',
            fisherPersonalType: 'Personal',
            fisherGroupType: 'Group',
            singleCatchType: 'Single catch',
            singleCatchRelease: 'Catch released',
            singleCatchAdiposeFin: 'Adipose fin',
            singleCatchWeight: 'Weight',
            bulkCatchType: 'Bulk catch',
            deletionReason: 'Please provide the reason for catch declaration deletion',
            declarationCatchDetailDeleteAlert: 'This catch will be removed after based on the following reason: {}',
            declarationCatchDetailUnsavedAlert: 'This catch will be added into declaration after saved',
            reason: 'Reason',
            declarationDeleted: 'Declaration was successfully deleted',
            declarationSaved: 'Declaration was successfully saved!',
            requestFailed: 'Something went wrong. Please try again or be in touch with omakala@luke.fi',
            fisherGroupFieldPrefix: "Organizer's",
            notNeededEx: {
                DECLARER_UNDER_AGE: 'Declaration is not required for people under the age of 16',
                OUTSIDE_DECLARATION_AREA: 'Declaration in the selected area is voluntary',
                ALREADY_DECLARED: 'Taxonomy "{}" is already declared',
                TAXONOMY_NOT_REQUIRED: 'This species in this area does not require a legal catch declaration.',
                // TAXONOMY_NOT_REQUIRED: 'Taxonomy "{}" does not require declaration for the selected area',
                NO_REQUIREMENTS_ACTIVE: 'No requirements are active',
                OTHER: 'Unknown reason',
            },
            notifications: {
                delete: {
                    lukeEmployeeNotice:
                        'The catch report has been removed from the catch registry. If the catch was completely incorrect, it should also be removed from the personal catch log. If it is a correction in some of the details, they should be corrected in the personal catch log.\n\nIf the user has automatic catch reporting enabled (which is the default), they must delete/correct the information in their personal catch log within the same day, otherwise the catch will be reported again',
                },
            },
            needToUpdateProfile: 'To submit declarations, you need to update your profile with these properties:',
            userShouldUseApp: 'We encourage you to declare catches using Omakala mobile application',
            regulation: {
                [DeclarationRegulation.INVASIVE_SET_FREE]: 'It would be desirable that invasive species are not released',
                [DeclarationRegulation.ADIPOSE_FIN_SET_FREE]:
                    'According to the regulation, {{ species }} with an adipose fin must be released in certain areas',
            },
            waterRegion: 'Water region',
            optionalDeclarationNotis:
                'This species in this area does not require a legal catch declaration. You may still proceed with a voluntary declaration.',
            optionalByAreaDeclarationNotis:
                'This area does not require a legal catch declaration. You may still proceed with a voluntary declaration.',
        },
        taxonomyType: {
            [KnownTaxonomyType.ALLIS_SHAD]: 'Allis shad',
            [KnownTaxonomyType.ALPINE_BULLHEAD]: 'Alpine bullhead',
            [KnownTaxonomyType.ARCTIC_CHAR]: 'Arctic char',
            [KnownTaxonomyType.ASP]: 'Asp',
            [KnownTaxonomyType.ATLANTIC_SALMON]: 'Atlantic salmon',
            [KnownTaxonomyType.ATLANTIC_STURGEON]: 'Atlantic sturgeon',
            [KnownTaxonomyType.BALTIC_HERRING]: 'Baltic herring',
            [KnownTaxonomyType.BELICA]: 'Belica',
            [KnownTaxonomyType.BLACK_GOBY]: 'Black goby',
            [KnownTaxonomyType.BLEAK]: 'Bleak',
            [KnownTaxonomyType.BRILL]: 'Brill',
            [KnownTaxonomyType.BROADNOSED_PIPEFISH]: 'Broadnosed pipefish',
            [KnownTaxonomyType.BROOK_LAMPREY]: 'Brook lamprey',
            [KnownTaxonomyType.BROOK_STICKLEBACK]: 'Brook stickleback',
            [KnownTaxonomyType.BROOK_TROUT]: 'Brook trout',
            [KnownTaxonomyType.BROWN_BULLHEAD]: 'Brown bullhead',
            [KnownTaxonomyType.BROWN_TROUT]: 'Brown trout',
            [KnownTaxonomyType.BURBOT]: 'Burbot',
            [KnownTaxonomyType.CHINESE_SLEEPER]: 'Chinese sleeper',
            [KnownTaxonomyType.CHUB]: 'Chub',
            [KnownTaxonomyType.COD]: 'Cod',
            [KnownTaxonomyType.COMMON_BREAM]: 'Common bream',
            [KnownTaxonomyType.COMMON_CARP]: 'Common carp',
            [KnownTaxonomyType.COMMON_DAB]: 'Common dab',
            [KnownTaxonomyType.COMMON_GOBY]: 'Common goby',
            [KnownTaxonomyType.COMMON_NASE]: 'Common nase',
            [KnownTaxonomyType.COMMON_RUDD]: 'Common rudd',
            [KnownTaxonomyType.COMMON_SEASNAIL]: 'Common seasnail',
            [KnownTaxonomyType.COMMON_WHITEFISH]: 'Common whitefish',
            [KnownTaxonomyType.CRUCIAN_CARP]: 'Crucian carp',
            [KnownTaxonomyType.DACE]: 'Dace',
            [KnownTaxonomyType.EELPOUT]: 'Eelpout',
            [KnownTaxonomyType.EURASIAN_MINNOW]: 'Eurasian minnow',
            [KnownTaxonomyType.EUROPEAN_ANCHOVY]: 'European anchovy',
            [KnownTaxonomyType.EUROPEAN_BITTERLING]: 'European bitterling',
            [KnownTaxonomyType.EUROPEAN_BULLHEAD]: 'European bullhead',
            [KnownTaxonomyType.EUROPEAN_EEL]: 'European eel',
            [KnownTaxonomyType.EUROPEAN_SEABASS]: 'European seabass',
            [KnownTaxonomyType.FIFTEENSPINED_STICKLEBACK]: 'Fifteenspined stickleback',
            [KnownTaxonomyType.FLOUNDER]: 'Flounder',
            [KnownTaxonomyType.FOURBEARD_ROCKLING]: 'Fourbeard rockling',
            [KnownTaxonomyType.FOURHORN_SCULPIN]: 'Fourhorn sculpin',
            [KnownTaxonomyType.GARFISH]: 'Garfish',
            [KnownTaxonomyType.GOLDFISH]: 'Goldfish',
            [KnownTaxonomyType.GRAYLING]: 'Grayling',
            [KnownTaxonomyType.GREAT_SANDEEL]: 'Great sandeel',
            [KnownTaxonomyType.GUDGEON]: 'Gudgeon',
            [KnownTaxonomyType.IDE]: 'Ide',
            [KnownTaxonomyType.LAKE_TROUT]: 'Lake trout',
            [KnownTaxonomyType.LAND_LOCKED_ATLANTIC_SALMON]: 'Landlocked atlantic salmon',
            [KnownTaxonomyType.LESSER_SANDEEL]: 'Lesser sandeel, sand lance',
            [KnownTaxonomyType.LONGSPINED_BULLHEAD]: 'Longspined bullhead',
            [KnownTaxonomyType.LUMPSUCKER]: 'Lumpsucker',
            [KnownTaxonomyType.MACKEREL]: 'Mackerel',
            [KnownTaxonomyType.NINESPINE_STICKLEBACK]: 'Ninespine stickleback',
            [KnownTaxonomyType.PELED]: 'Peled',
            [KnownTaxonomyType.PERCH]: 'Perch',
            [KnownTaxonomyType.PIKE]: 'Pike',
            [KnownTaxonomyType.PIKE_PERCH]: 'Pike-perch',
            [KnownTaxonomyType.PINK_SALMON]: 'Pink salmon',
            [KnownTaxonomyType.PRUSSIAN_CARP]: 'Prussian carp',
            [KnownTaxonomyType.PUMPKINSEED]: 'Pumpkinseed',
            [KnownTaxonomyType.RAINBOW_TROUT]: 'Rainbow trout',
            [KnownTaxonomyType.RIVER_LAMPREY]: 'River lamprey',
            [KnownTaxonomyType.ROACH]: 'Roach',
            [KnownTaxonomyType.ROCK_GUNNEL]: 'Rock gunnel',
            [KnownTaxonomyType.ROUND_GOBY]: 'Round goby',
            [KnownTaxonomyType.RUFFE]: 'Ruffe',
            [KnownTaxonomyType.RUSSIAN_STURGEON]: 'Danube sturgeon, Russian sturgeon',
            [KnownTaxonomyType.SAND_GOBY]: 'Sand goby',
            [KnownTaxonomyType.SEA_LAMPREY]: 'Sea lamprey',
            [KnownTaxonomyType.SHORTHORN_SCULPIN]: 'Shorthorn sculpin',
            [KnownTaxonomyType.SIBERIAN_STURGEON]: 'Siberian sturgeon',
            [KnownTaxonomyType.SICHEL]: 'Sichel',
            [KnownTaxonomyType.SILVER_BREAM]: 'Silver bream',
            [KnownTaxonomyType.SMELT]: 'Smelt',
            [KnownTaxonomyType.SNAKE_BLENNY]: 'Snake blenny',
            [KnownTaxonomyType.SPINED_LOACH]: 'Spined loach',
            [KnownTaxonomyType.SPRAT]: 'Sprat',
            [KnownTaxonomyType.STONE_LOACH]: 'Stone loach',
            [KnownTaxonomyType.STRAIGHTNOSE_PIPEFISH]: 'Straightnose pipefish',
            [KnownTaxonomyType.STURGEON]: 'Sturgeon',
            [KnownTaxonomyType.TENCH]: 'Tench',
            [KnownTaxonomyType.THREESPINE_STICKLEBACK]: 'Threespine stickleback',
            [KnownTaxonomyType.TURBOT]: 'Turbot',
            [KnownTaxonomyType.TWAITE_SHAD]: 'Twaite shad',
            [KnownTaxonomyType.TWO_SPOTTED_GOBY]: 'Two-spotted goby',
            [KnownTaxonomyType.VENDACE]: 'Vendace',
            [KnownTaxonomyType.VIMBA_BREAM]: 'Vimba bream',
            [KnownTaxonomyType.WELS_CATFISH]: 'Wels catfish',
            [KnownTaxonomyType.WHITE_FINNED_GUDGEON]: 'White-finned gudgeon',
            [KnownTaxonomyType.ZOPE]: 'Zope',
            [KnownTaxonomyType.OTHER]: 'Others',
        },
        taxonomyTypeProperty: {
            [TaxonomyTypePropertyName.ADIPOSE_FIN_CUT]: {
                name: 'adipose fin cut',
                bulkName: 'adipose fin cut',
                estimatedName: 'adipose fin cut',
            },
            [TaxonomyTypePropertyName.ADIPOSE_FIN]: {
                name: 'adipose fin',
                bulkName: 'adipose fin',
                estimatedName: 'adipose fin',
            },
            [TaxonomyTypePropertyName.CATCH_AND_RELEASE]: {
                name: 'catch released',
                bulkName: 'catch released',
                estimatedName: 'catch released',
            },
            [TaxonomyTypePropertyName.LENGTH]: {
                name: 'length',
                bulkName: 'total length',
                estimatedName: 'estimated length',
            },
            // these two properties are only received for data validation,
            // and shouldn't be displayed to the user - which is why they're empty
            [TaxonomyTypePropertyName.LENGTH_WEIGHT_RATIO_PARAM_1]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.LENGTH_WEIGHT_RATIO_PARAM_2]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.MUST_RELEASE_NON_ADIPOSE_FIN]: {
                name: '',
                bulkName: '',
            },
            [TaxonomyTypePropertyName.WEIGHT]: {
                name: 'weight',
                bulkName: 'total weight',
                estimatedName: 'estimated weight',
            },
        },
        map: {
            searchBar: {
                clearResults: 'Clear search results',
                label: 'Search',
                noResultsFound: 'No results found',
                placeholder: 'Search...',
                resultsFound: 'Found {{ count }} search results',
                requestError: 'The search cannot be performed. The background system cannot be reached.',
                result: {
                    region: 'Region',
                    subregion: 'Subregion',
                    municipality: 'Municipality',
                    placeType: 'Type',
                    placeTypeDescription: 'Description',
                    placeTypeCategory: 'Category',
                    placeTypeGroup: 'Group',
                    placeTypeSubgroup: 'Subgroup',
                },
            },
            layers: {
                [MapLayer.ACTIVITY]: { name: 'Activities', settings: {} },
                [MapLayer.CATCH]: { name: 'Catches', settings: {} },
                [MapLayer.WFS_FISHING_RESTRICTIONS]: { name: 'Fishing restrictions near me', settings: {} },
                [MapLayer.HIGHLIGHT_LAYER]: { name: 'Highlights', settings: {} },
                [MapLayer.POINT_OF_INTEREST]: { name: 'Points of interest', settings: {} },
                [MapLayer.POSITION]: { name: 'Position', settings: {} },
                [MapLayer.ROUTE]: { name: 'Route', settings: {} },
                [MapLayer.ORTHOPHOTO_LAYER]: { name: 'Aerial', settings: {} },
                [MapLayer.VECTOR_LAYER]: { name: 'General', settings: {} },
                [MapLayer.TERRAIN_LAYER]: { name: 'Terrain', settings: {} },
                [MapLayer.DEPTH_CURVES]: { name: 'Depth curves', settings: {} },
                [MapLayer.WMS_FISHING_RESTRICTIONS]: {
                    name: 'Fishing Restrictions',
                    settings: {
                        [LayerCommonSettingName.FROM_DATE]: 'From date',
                        [LayerCommonSettingName.TO_DATE]: 'To date',
                        deviatingRestrictionsOnly: 'Only deviating catch size and rest period restrictions',
                    },
                },
            },
            settings: {
                mapLayer: {
                    title: 'Layer settings',
                },
            },
        },
    },
};

export default translations;
