import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Skeleton,
    Typography,
    Alert,
    Checkbox,
    FormControlLabel,
    TextField,
    styled,
} from '@mui/material';
import {
    DeclarationRequirementDetails,
    ExternalFisherReferenceTarget,
    DeclarationDetails,
    DeclarationSource,
    DeclarationCatchDetails,
    DeclarationFisherDetails,
    ConsentIdentifier,
} from '@oma-kala-shared/core/model';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setErrorMessageWithTimeout } from 'app/state/message/messageSlice';
import { DeclarationFisherDetail, DeclarationFisherDetailRef } from './fisher/DeclarationFisherDetail';

import { useDispatch, useSelector } from 'react-redux';
import { DeclarationCatchDetail } from './catch/DeclarationCatchDetail';
import { DeclarationCatchItem } from 'app/components/declaration/display/DeclarationCatchItem';
import { saveDeclaration } from 'app/state/thunks/saveDeclaration';
import { useSyncToken } from 'app/hooks';
import { selectUserData, selectUserIsAdmin, selectUserIsEditor } from 'app/state/user/userSlice';
import { store } from 'app/state/store';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'app/model/Route';
import usePrompt from 'app/hooks/router/usePrompt';
import AddIcon from '@mui/icons-material/Add';

import { getMissingFisherProperties, getUseAppWarningDisabled, storeUseAppWarningDisabled } from 'app/logic/DeclarationService';
import { join } from 'lodash';
import { MessageType, OmaKalaLanguageCode } from 'app/model';
import { Message, MessageExtraContentType } from 'app/model/Message';
import { AlertDialogComponent } from 'app/components/common/AlertDialogComponent';
import DeclarationCatchList from '../display/DeclarationCatchList';
import i18n from 'i18n';
import { getConsentDisplayText } from '@oma-kala-shared/core/logic';
import SkeletonLoader from './SkeletonLoader';
import { appTheme } from 'app/theme';
import PublishIcon from '@mui/icons-material/Publish';

const DEFAULT_NEW_DECLARATION: DeclarationDetails = {
    source: DeclarationSource.WEBFORM,
    id: null,
    sequentialId: null,
    createdAt: null,
    fishers: [],
    catches: [],
    saveExactLocation: true,
    saveExactLocationConsentId: null,
    additionalInformation: null,
};

export type CreateDeclarationFormProps = {
    isLoading: boolean;
    declarationRequirement: DeclarationRequirementDetails | null;
    isGroupDeclaration: boolean;
    onCatchFormClose?: () => void;
    onCatchFormShow?: () => void;
};

export type CreateDeclarationFormRef = {
    openCatchForm: () => void;
};

const CreateDeclarationForm = React.forwardRef<CreateDeclarationFormRef, CreateDeclarationFormProps>(
    ({ isLoading, declarationRequirement, isGroupDeclaration, onCatchFormClose = () => {}, onCatchFormShow = () => {} }, ref) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const [isDirty, setIsDirty] = useState<boolean>(false);
        usePrompt(t('common.form.leavingNotification'), isDirty);
        const [currentDeclaration, setCurrentDeclaration] = useState<DeclarationDetails>(DEFAULT_NEW_DECLARATION);
        const [isCatchFormOpen, setIsCatchFormOpen] = useState<boolean>(true);
        const [editingCatch, setEditingCatch] = useState<DeclarationCatchDetails | null>(null);
        const fisherFormRef = useRef<DeclarationFisherDetailRef>(null);
        const dispatch = useDispatch();
        const userData = selectUserData(store.getState());
        const showCatchForm = currentDeclaration.catches.length === 0 || isCatchFormOpen === true;
        const isAdminOrEditor = useSelector(selectUserIsAdmin) === true || useSelector(selectUserIsEditor) === true;
        const [missingUserProperties, setMissingUserProperties] = useState<string[]>([]);
        const useAppWarningDisabled = getUseAppWarningDisabled();
        const [alert, setAlert] = useState<Message | null>(null);

        // We just want to get concert information to get the label to show in checkbox below
        const exactLocationConsent = userData?.consentData?.find(
            consent => consent.consentId === ConsentIdentifier.ALLOW_EXACT_LOCATION_FOR_RESEARCH_USAGE
        );

        const [useExactLocation, setUseExactLocation] = useState<boolean>(true);
        const [exactLocationConsentId, setExactLocationConsentId] = useState<string | null>(null);
        const additionalInfoRef = useRef<HTMLTextAreaElement>(null);

        useEffect(() => {
            // If admin or editor, leave consentId as null.
            // If not, get the consent id from user consents. This is stored in the declaration table in backend
            if (isAdminOrEditor) {
                return;
            }
            if (exactLocationConsent) {
                setExactLocationConsentId(exactLocationConsent.id);
                setUseExactLocation(exactLocationConsent.accepted);
            } else {
                console.warn('Consent ALLOW_EXACT_LOCATION_FOR_RESEARCH_USAGE is missing. Default values used');
            }
        }, []);

        const [syncToken, isProcessing] = useSyncToken({
            onSuccess: () => {
                setTimeout(() => {
                    navigate(isGroupDeclaration ? RoutePath.CATCH_DECLARATION_GROUP : RoutePath.CATCH_DECLARATION_PERSONAL);
                }, 2000);
            },
            onError: () => {},
        });

        useEffect(() => {
            if (!showCatchForm) {
                onCatchFormClose();
            } else {
                onCatchFormShow();
            }
        }, [showCatchForm]);

        useImperativeHandle(ref, () => ({
            openCatchForm: () => {
                if (!isCatchFormOpen) {
                    setIsCatchFormOpen(true);
                    setEditingCatch(null);
                }
            },
        }));

        const handleFisherDetailsChange = (latestFisherDetail: DeclarationFisherDetails | null) => {
            const latestNewDeclaration: DeclarationDetails = {
                ...currentDeclaration,
            };

            if (latestFisherDetail) {
                latestNewDeclaration.fishers = [latestFisherDetail];
            }

            fisherFormRef.current?.validate();
            setCurrentDeclaration(latestNewDeclaration);
            setIsDirty(true);
        };

        const handleCatchDetailsChange = (latestCatchDetail: DeclarationCatchDetails | null) => {
            const latestNewDeclaration: DeclarationDetails = {
                ...currentDeclaration,
            };

            if (latestCatchDetail) {
                latestNewDeclaration.catches = [latestCatchDetail];
            }
            // setCurrentDeclaration(latestNewDeclaration);
            setEditingCatch(latestCatchDetail);
            setIsDirty(true);
        };

        // If user is regular fisher, we display a message if there are missing user/fisher properties
        useEffect(() => {
            if (!userData || !declarationRequirement) {
                return;
            }
            if (!isAdminOrEditor) {
                const missingProperties = getMissingFisherProperties(userData, declarationRequirement).map(e => t(`catchDeclaration.${e}`));
                setMissingUserProperties(missingProperties);
            }
        }, [userData, declarationRequirement]);

        /**
         * If user is not admin/editor, and it is not a group declaration, encourage them to use the app instead
         */
        useEffect(() => {
            if (!isAdminOrEditor && !useAppWarningDisabled && !isGroupDeclaration) {
                setAlert({
                    text: t('catchDeclaration.userShouldUseApp'),
                    type: MessageType.INFO,
                    extraContent: [
                        {
                            type: MessageExtraContentType.Checkbox,
                            label: t('common.dialog.doNotShowAgain'),
                            change: x => storeUseAppWarningDisabled(x.target.checked === true),
                        },
                    ],
                });
            }
        }, []);

        /**
         * Submit declaration to the backend
         */
        const handleSubmitDeclaration = () => {
            if (!fisherFormRef.current?.validate() || currentDeclaration.catches.length === 0) {
                dispatch(
                    setErrorMessageWithTimeout({ text: t('catchDeclaration.newDeclaration.messages.validateErrorMessage'), timeout: 3000 })
                );

                return;
            }

            const fisherDetails = fisherFormRef.current?.getDeclarationFisherDetails();
            fisherDetails!.externalReferences = [
                {
                    externalId: userData?.id!,
                    referenceTarget: ExternalFisherReferenceTarget.OMAKALA,
                    externalEmail: userData?.email!,
                },
            ];

            const latestNewDeclaration: DeclarationDetails = {
                ...currentDeclaration,
                source: DeclarationSource.WEBFORM,
                id: null,
                sequentialId: null,
                createdAt: null,
                saveExactLocation: useExactLocation,
                saveExactLocationConsentId: exactLocationConsentId,
                additionalInformation: additionalInfoRef.current?.value ?? '',
            };
            latestNewDeclaration.fishers = [fisherDetails!];
            setCurrentDeclaration(latestNewDeclaration);
            dispatch(saveDeclaration(syncToken, latestNewDeclaration));
            setIsDirty(false);
        };

        /**
         * When we save the catch info it can be edit or create new catch for current declaration
         * we need to also include the fisher's details because this function will make this component
         * rerender so we need to let the fisher form render with latest data otherwise we will face
         * problem that all required fields is empty although in the UI user filled in the input
         *
         * @param {DeclarationCatchDetails} catchInfo The saved catch info
         */
        const handleCreateDeclarationCatchFormSave = (catchInfo: DeclarationCatchDetails) => {
            let latestNewDeclaration;
            if (editingCatch === null) {
                latestNewDeclaration = {
                    ...currentDeclaration,
                    catches: [...currentDeclaration.catches, catchInfo],
                };
            } else {
                let exist = false;
                const latestCatches = currentDeclaration.catches.map(catchItem => {
                    if (catchItem.tempId === editingCatch.tempId) {
                        exist = true;
                        return catchInfo;
                    }

                    return catchItem;
                });

                if (exist === false) {
                    latestCatches.push(catchInfo);
                }

                latestNewDeclaration = {
                    ...currentDeclaration,
                    catches: latestCatches,
                };
            }

            setCurrentDeclaration(latestNewDeclaration);
            setIsCatchFormOpen(false);
            setEditingCatch(null);
        };

        const handleCreateDeclarationCatchFormCancel = () => {
            // This happen when declaration has no catches and user click cancel in open form
            if ((currentDeclaration.catches.length === 0 && editingCatch !== null) || isCatchFormOpen === false) {
                navigate(isGroupDeclaration ? RoutePath.CATCH_DECLARATION_GROUP : RoutePath.CATCH_DECLARATION_PERSONAL);
                return;
            }

            setEditingCatch(null);
            setIsCatchFormOpen(false);
        };

        const handleEditCatch = (editItem: DeclarationCatchDetails) => {
            let editCatch;
            // Edit already published catch
            if (editItem.id) {
                editCatch = currentDeclaration.catches.find((catchDetail: DeclarationCatchDetails) => editItem.id === catchDetail.id);
            } else {
                // Edit catch added while editing and it is not published
                editCatch = currentDeclaration.catches.find(
                    (catchDetail: DeclarationCatchDetails) => editItem.tempId !== undefined && editItem.tempId === catchDetail.tempId
                );
            }

            setEditingCatch(editCatch!);
            setIsCatchFormOpen(true);
        };

        const handleRemoveCatch = (removeItem: DeclarationCatchDetails) => {
            let newCatches;
            // Remove already published catch
            if (removeItem.id) {
                newCatches = currentDeclaration.catches.map((catchDetail: DeclarationCatchDetails) => {
                    if (removeItem.id === catchDetail.id) {
                        return removeItem;
                    }

                    return catchDetail;
                });
            } else {
                // Remove catch added while editing and it is not published
                newCatches = currentDeclaration.catches.filter(
                    (catchDetail: DeclarationCatchDetails) => removeItem.tempId !== undefined && removeItem.tempId !== catchDetail.tempId
                );
            }

            const latestNewDeclaration = {
                ...currentDeclaration,
                catches: newCatches,
            };

            setCurrentDeclaration(latestNewDeclaration);

            if (latestNewDeclaration.catches.length === 0) {
                setIsCatchFormOpen(true);
            } else {
                setIsCatchFormOpen(false);
            }
        };

        const handleCopyCatch = (copyItem: DeclarationCatchDetails) => {
            const newCatch = {
                ...copyItem,
                geometry: copyItem.geometry ?? copyItem.generalizedLocation!.geometry,
                generalizedLocation: {
                    ...copyItem.generalizedLocation!,
                    id: null,
                },
                tempId: `catchDetails_${new Date().getTime().toString()}` as string,
            };

            // Need to remove id to be able to create new catch
            if (newCatch.id) {
                delete newCatch.id;
                delete newCatch.sequentialId;

                // Inside the catch property we also need to remove the id out of it
                const pureProperties = newCatch.catchProperties.map(property => {
                    const { id, ...pureProperty } = property;
                    return pureProperty;
                });

                newCatch.catchProperties = pureProperties;
            }

            const latestNewDeclaration = {
                ...currentDeclaration,
                catches: [...currentDeclaration.catches, newCatch],
            };
            setCurrentDeclaration(latestNewDeclaration);
        };

        if (isLoading || declarationRequirement === null) {
            return <SkeletonLoader />;
        }

        return (
            <>
                {missingUserProperties.length > 0 && (
                    <Alert severity="error">{t('catchDeclaration.needToUpdateProfile') + '\n\n' + join(missingUserProperties, ', ')}</Alert>
                )}
                <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 2 }}>
                    {t('catchDeclaration.newDeclaration.fisherInfoTitle')}
                </Typography>

                <DeclarationFisherDetail
                    ref={fisherFormRef}
                    declaration={currentDeclaration}
                    declarationFisherRequirementProperties={declarationRequirement.requiredFisherProperties}
                    onValueChange={handleFisherDetailsChange}
                    isGroupDeclaration={isGroupDeclaration}
                />

                {isAdminOrEditor && (
                    <>
                        {exactLocationConsent && (
                            <FormControlLabel
                                sx={{ marginTop: 3 }}
                                control={<Checkbox checked={useExactLocation} />}
                                label={getConsentDisplayText(exactLocationConsent, i18n.language as OmaKalaLanguageCode)}
                                value={useExactLocation}
                                onChange={() => setUseExactLocation(!useExactLocation)}
                            />
                        )}
                        <TextField
                            sx={{ marginTop: 3 }}
                            fullWidth={true}
                            id="declaration-additional-info"
                            label={t('catchDeclaration.newDeclaration.additionalInfo')}
                            placeholder=""
                            multiline
                            minRows={5}
                            inputRef={additionalInfoRef}
                        />
                    </>
                )}

                {!showCatchForm && (
                    <>
                        <Box sx={{ marginTop: 6, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h5" component="span" sx={{ marginBottom: 2, marginTop: 2 }}>
                                {t('catchDeclaration.newDeclaration.catchListTitle')}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                <Button onClick={() => setIsCatchFormOpen(true)} sx={actionButtonStyle} variant="contained" color="primary">
                                    <AddIcon fontSize={'small'} sx={{ marginRight: 1 }} />
                                    <Typography variant="body2">{t('catchDeclaration.newDeclaration.createNewCatch') as string}</Typography>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmitDeclaration}
                                    disabled={isProcessing}
                                    sx={actionButtonStyle}
                                >
                                    {isProcessing ? (
                                        <CircularProgress color="primary" size={16} sx={{ position: 'absolute', right: 10 }} />
                                    ) : (
                                        <PublishIcon fontSize={'small'} sx={{ marginRight: 1 }} />
                                    )}
                                    <Typography variant="body2">
                                        {t('catchDeclaration.newDeclaration.submitDeclaration') as string}
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </>
                )}

                {showCatchForm && (
                    <>
                        <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 7 }}>
                            {t('catchDeclaration.newDeclaration.catchInfoTitle')}
                        </Typography>
                        <DeclarationCatchDetail
                            declaration={currentDeclaration}
                            catchDetail={editingCatch}
                            declarationTaxonomyRequirement={declarationRequirement.requiredTaxonomies}
                            onCancel={handleCreateDeclarationCatchFormCancel}
                            onSave={handleCreateDeclarationCatchFormSave}
                            allowCancel={true}
                            onValueChange={handleCatchDetailsChange}
                        />
                    </>
                )}
                {!showCatchForm && (
                    <>
                        <Box>
                            <DeclarationCatchList
                                catches={currentDeclaration.catches}
                                sorter={DeclarationCatchList.sortByDate}
                                itemRenderer={(catchDetail: DeclarationCatchDetails, index: number) => {
                                    return (
                                        <DeclarationCatchItem
                                            key={catchDetail.tempId}
                                            catchItem={catchDetail}
                                            onCopy={() => {
                                                handleCopyCatch(catchDetail);
                                            }}
                                            onDelete={() => {
                                                handleRemoveCatch(catchDetail);
                                            }}
                                            onEdit={() => {
                                                handleEditCatch(catchDetail);
                                            }}
                                            isListView={false}
                                        />
                                    );
                                }}
                            />
                        </Box>
                        <CancelButton
                            variant="outlined"
                            onClick={handleCreateDeclarationCatchFormCancel}
                            sx={{
                                minWidth: 200,
                                backgroundColor: 'white',
                                color: '#0033A0',
                                boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                '&:hover': 'white',
                                float: 'right',
                                marginTop: 4,
                            }}
                        >
                            {t('common.cancel')}
                        </CancelButton>
                    </>
                )}
                {alert && <AlertDialogComponent props={alert} open={alert !== null} handleClose={() => setAlert(null)} />}
            </>
        );
    }
);

CreateDeclarationForm.displayName = 'CreateDeclarationForm';

export default CreateDeclarationForm;

const actionButtonStyle = {
    height: 35,
    marginLeft: 1.5,
    backgroundColor: appTheme.palette.primary.main,
    color: '#fff',
    '&:hover': {
        backgroundColor: 'rgba(0,83,166,0.75)',
    },
} as const;

const CancelButton = styled(Button)({
    '&:hover': {
        backgroundColor: 'white',
    },
});
