import React, { useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import { GridFields, GridValue, FieldValueType, GridField, DynamicFieldRef, DynamicGridRef, DynamicGridError } from '.';
import DynamicField from './DynamicField';
import Validator from 'validatorjs';
import { useTranslation } from 'react-i18next';

type DynamicGridProps = {
    gridFields: GridFields[];
    onValueChange: (fieldsValue: GridValue) => void;
};

const DynamicGrid = React.forwardRef<DynamicGridRef, DynamicGridProps>(({ gridFields, onValueChange }, ref) => {
    const formRef = useRef<GridValue | null>(null);
    const { t, i18n } = useTranslation();

    useImperativeHandle(ref, () => ({
        validate: () => {
            return validateGrid();
        },
        getValue: () => {
            return formRef.current ?? null;
        },
        setError: (error: DynamicGridError) => {
            for (const [key, value] of Object.entries(error)) {
                if (refsByName[key]) {
                    setFieldError(key, value);
                }
            }
        },
    }));

    // Need to validate again to show error in right language
    useEffect(() => {
        Validator.register(
            'greater_than',
            (value, requirement, attribute) => {
                // requirement parameter defaults to null
                return value > requirement;
            },
            t('catchDeclaration.validation.greaterThan')
        );

        validateGrid();
    }, [i18n.language]);

    const setFieldError = (field: string, error: string) => {
        refsByName[field].current?.setError([error]);
    };

    const validateGrid = () => {
        let failedCount = 0;
        gridFields.forEach(row => {
            row.forEach((field: GridField) => {
                if (!validateField(field.name)) {
                    failedCount += 1;
                }
            });
        });

        const isValid = failedCount === 0;

        // if (isValid && extraValidator !== undefined) {
        //     isValid = extraValidator(formRef.current, refsByName);
        // }

        return isValid;
    };

    const validateField = (fieldName: string): boolean => {
        if (!validationRuleByName[fieldName]) {
            return true;
        }

        Validator.useLang(i18n.language);
        const validator = new Validator(
            {
                [fieldName]: formRef.current?.[fieldName],
            },
            { [fieldName]: validationRuleByName[fieldName] }
        );
        validator.setAttributeNames(labels);

        validator.passes(() => refsByName[fieldName].current?.setError(null));
        validator.fails(() => refsByName[fieldName].current?.setError(validator.errors.get(fieldName)));
        return validator.check();
    };

    const setValueToRef = (fieldName: string, value: FieldValueType) => {
        formRef.current = {
            ...formRef.current,
            [fieldName]: value,
        };
    };

    const handleValueChange = (fieldName: string, value: FieldValueType) => {
        setValueToRef(fieldName, value);
        // validateGrid();
        onValueChange(formRef.current ?? null);
    };

    const {
        refs: refsByName,
        rules: validationRuleByName,
        labels,
    } = useMemo(() => {
        const refs: {
            [key: string]: React.RefObject<DynamicFieldRef>;
        } = {};

        const rules: {
            [key: string]: string;
        } = {};

        const labels: {
            [key: string]: string;
        } = {};

        gridFields.forEach(row => {
            row.forEach((field: GridField) => {
                refs[field.name] = React.createRef<DynamicFieldRef>();
                rules[field.name] = field.validationRule ?? 'required';
                labels[field.name] = field.label!;
                setValueToRef(field.name, field.value!);
            });
        });
        return { refs, rules, labels };
    }, [gridFields]);

    return (
        <Box>
            {gridFields.map((row, rowIndex) => (
                <Grid container key={rowIndex}>
                    {row.map(field => (
                        <Grid item key={field.id} {...field.containerProps}>
                            <DynamicField field={field} onChange={handleValueChange} ref={refsByName[field.name]} />
                        </Grid>
                        // <DynamicField key={field.id} field={field} onChange={handleValueChange} ref={refsByName[field.name]} />
                    ))}
                </Grid>
            ))}
        </Box>
    );
});

DynamicGrid.displayName = 'Dynamic Grid';

export default DynamicGrid;
