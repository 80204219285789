import { Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { OMAKALA_LANGUAGE_ORDER } from 'app/model';
import { changeLanguage } from 'app/logic/LanguageService';

type LanguageMenuProps = Pick<React.ComponentProps<typeof Menu>, 'anchorEl' | 'onClose' | 'onClick'>;

export const LanguageMenu = ({ anchorEl, onClose, onClick }: LanguageMenuProps) => {
    const isMenuOpen = Boolean(anchorEl);
    const { i18n } = useTranslation();

    const setLanguage = (code: string) => changeLanguage(i18n, code.toLowerCase());

    const renderMenuItem = () => {
        return OMAKALA_LANGUAGE_ORDER.map(languageCode => {
            return (
                <MenuItem
                    key={languageCode}
                    style={{ width: 100 }}
                    selected={languageCode === i18n.language}
                    onClick={() => setLanguage(languageCode.toUpperCase())}
                    sx={{
                        '&.Mui-selected': { backgroundColor: 'primary.main', color: 'white' },
                        '&.Mui-selected:hover': { backgroundColor: 'primary.light' },
                        '&:hover': {
                            backgroundColor: 'grey.300',
                        },
                    }}
                >
                    {languageCode.toUpperCase()}
                </MenuItem>
            );
        });
    };

    return (
        <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={onClose}
            onClick={onClick}
            autoFocus={false}
            MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
            }}
        >
            {renderMenuItem()}
        </Menu>
    );
};
